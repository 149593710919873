<template>
	<li class="gap-20 border-t-2 border-medium-green pt-8">
		<router-link :to="{ path: url }">
			<span>{{ props.title }}</span>
		</router-link>
	</li>

	<li
		v-for="(item, index) in props.itemList"
		:key="index"
		:class="{
			'bg-dark-greeen': !isActive(item.url),
			'bg-white': isActive(item.url),
		}"
	>
		<router-link :to="{ path: item.url }">
			<span
				:class="{
					'text-white': !isActive(item.url),
					'text-bright-orange': isActive(item.url),
				}"
				>{{ item.name }}</span
			>
		</router-link>
	</li>
</template>

<script setup lang="ts">
	import { defineProps } from "vue";
	import { useRoute } from "vue-router";

	type SectionItem = {
		url: string;
		name: string;
	};

	interface Props {
		title: string;
		url: string;
		itemList: SectionItem[];
	}
	const isActive = (url: string) => {
		// Get the current route's path from Vue Router
		const currentRoute = useRoute();
		return url === currentRoute.path;
	};

	const props = defineProps<Props>();
</script>

<style scoped>
	li {
		@apply min-w-max list-none p-2.5;
	}
	li a {
		@apply font-bold text-bright-orange no-underline;
	}
	li a span {
		@apply text-base font-semibold;
	}
</style>
