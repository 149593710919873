<script setup lang="ts">
	import { DialogLayout } from "@jca/libs/ui";
	import { ref } from "vue";
	
	const dialogRef = ref<HTMLDialogElement>();

	function openDialog() {
		dialogRef.value?.showModal();
	}

	function closeDialog() {
		dialogRef.value?.close();
	}

	defineExpose({
		openDialog,
	});
</script>

<template>
	<dialog ref="dialogRef">
		<DialogLayout>
			<template #title>
				<span>Information</span>
			</template>

			<hr class="mt-3" />
			<div class="mt-3 flex items-center justify-center p-5">
				<ul>
					<li class="text-sm list-disc">
						The active connections list is actually a list of end points that are connected to by entities (remote machine and support personnel)
					</li>
					<li class="text-sm list-disc">
						End points are maintained as long as they have at least one entity connected
					</li>
                    <li class="text-sm list-disc">
                        End points can exist for up to approximately 30 seconds after all entity connections have been closed.
                    </li>
                    <li class="text-sm list-disc">
                        When end points are initially created, there is a grace period of approximately 120 seconds, where the system is waiting for entities to connect.
                    </li>
				</ul>
			</div>

			<hr class="mt-3" />

			<template #actions>
		
				<button
					class="mb-2 mr-2 flex items-center rounded bg-light-orange px-4 py-2 font-bold text-dark-orange md:mb-0"
					@click="closeDialog"
				>
					Close
				</button>
			</template>
		</DialogLayout>
	</dialog>
</template>
