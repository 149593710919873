<script setup lang="ts">
	import { defineProps, ref } from "vue";
	import { DataTableFilterMetaData } from "primevue/datatable";
	import DataTable from "primevue/datatable";
	import Loading from "@/components/Loading.vue";
	import Search from "@/components/Search.vue";
	import ActionButton from "@/components/ActionButton.vue";
	import Column from "primevue/column";
	import { AuditTrailsType } from "@/composables/api-models";
	import { AuditTrailsPageType } from "@/composables/audit-trails-api";
	import { getDuration } from "@/composables/time";
	import { formatDateTime } from "@jca/libs/formatting";

	const props = defineProps<{
		tasks: AuditTrailsType[];
		type: AuditTrailsPageType; // type of the history, either active or history
        isLoading: boolean;
	}>();

	const emit = defineEmits<{
		terminateConnection: [remoteId: string];
	}>();

	
	var showTooltip = ref(false); // tooltip to show that ssh command is copied
	var activeRowPublicIP = ref(""); // public ip of the active row to determine which row's tooltip to show
	const tooltipText = ref("SSH Command Copied!");

	// this holds the filter values from primevue table
	const filters = ref<{ global: DataTableFilterMetaData }>({
		global: {
			value: null,
			matchMode: "contains",
		},
	});

	function toggleTooltip() {
		showTooltip.value = true; // Toggle tooltip visibility
		console.log("showTooltip", showTooltip);
		// Hide tooltip after a short duration
		setTimeout(() => {
			showTooltip.value = false;
		}, 2000);
	}

	// Copy the text to clipboard
	async function copyText(text: string) {
		try {
			await navigator.clipboard.writeText("ssh -p 2281 jca@" + text);
			activeRowPublicIP.value = text;
			toggleTooltip();
		} catch (error) {
			console.error("Unable to copy text to clipboard:", error);
		}
	}

	function CalendarActionClicked() {
		alert("Calendar action in unavailable");
	}

	function ExportActionClicked() {
		downloadCSV();
	}

	function convertToCSV() {
		var items = props.tasks;
		var csv = "";

		if (items) {
			const headers = Object.keys(items[0]) as (keyof AuditTrailsType)[];
			csv += headers.join(",") + "\n";

			items.forEach((item: AuditTrailsType) => {
				csv +=
					headers.map(header => replacer(item[header] as string)).join(",") +
					"\n";
			});
		}
		return csv;
	}

	function replacer(value: string) {
		return value === null ? "" : value;
	}

	function downloadCSV() {
		const filenameWithoutExtension = ref("");
		if (props.type === AuditTrailsPageType.Active) {
			filenameWithoutExtension.value = "ActiveECSTasks";
		} else {
			filenameWithoutExtension.value = "HistoryECSTasks";
		}
		const csvData = convertToCSV();
		const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
		const link = document.createElement("a");
		const url = URL.createObjectURL(blob);

		const today = new Date();
		const currtime =
			today.getDate() + "_" + today.getMonth() + "_" + today.getFullYear();

		link.setAttribute("href", url);
		link.setAttribute(
			"download",
			`${filenameWithoutExtension.value}_${currtime}.csv`,
		);
		link.style.visibility = "hidden";
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}
	

 
</script>

<template>
	<div class="box-border flex min-h-0 flex-col gap-4 p-4">
		<div
			class="customizedPrimeVueTable flex grid grid-cols-4 flex-col gap-2 gap-4 overflow-y-auto"
		>
			<DataTable
				tableStyle="min-width: 100%"
				paginator
				:loading="isLoading"
				:filters="filters"
				:rows="10"
				:value="props.tasks"
				class="col-span-4"
			>
				<template #header>
					<div
						class="mb-5 flex justify-between"
						v-if="props.tasks"
					>
						<Search :filters-prop="filters" />
						<!-- Search component -->
						<div style="display: flex">
							<ActionButton
								:action-text="'Export'"
								@action="ExportActionClicked"
								:img-src="'export_icon'"
							/>
							<!-- <ActionButton
								:action-text="'This Week'"
								@action="CalendarActionClicked"
								:img-src="'calendar_icon'"
								v-if="props.type === AuditTrailsPageType.History"
							/> -->
						</div>
					</div>
				</template>
				<Column
					field="ProjectName"
					header="Project Name"
					sortable
				/>
				<Column
					field="MachineName"
					header="Machine Name"
					sortable
                    v-if="props.type === AuditTrailsPageType.Active"
				/>
				<Column
					field="RemoteId"
					header="ECS Task ID"
					sortable
				>
					<template #body="slotProps">
						{{ slotProps.data.RemoteId.split("/")[1] }}
						<!-- Display unique id only -->
					</template>
				</Column>

				<Column
					field="PublicIP"
					header="Public IP"
					sortable
					style="width: auto"
				>
					<template #body="slotProps">
						<div
							style="
								display: flex;
								align-items: center;
								justify-content: center;
							"
						>
							<button
								@click="copyText(slotProps.data.PublicIP)"
								class="custom-button"
							>
								<span style="margin-right: 10px">
									{{ slotProps.data.PublicIP }}</span
								>
								<img
									src="@/assets/activeConnections/copy_icon.svg"
									alt="Copy icon"
									v-if="props.type === AuditTrailsPageType.Active"
								/>
							</button>
							<div
								v-if="
									showTooltip && activeRowPublicIP === slotProps.data.PublicIP
								"
							>
								{{ tooltipText }}
							</div>
						</div>
					</template>
				</Column>

				<Column
					field="StartTime"
					header="Local Start Date/Time"
					sortable
				>
						<template #body="slotProps">
							{{ formatDateTime(new Date(slotProps.data.StartTime)) }}
						</template>
				</Column>

				<Column
					field="EndTime"
					header="Duration"
					sortable
					v-if="props.type === AuditTrailsPageType.History"
				>
                    <template #body="slotProps">
						{{ getDuration(slotProps.data.StartTime,slotProps.data.EndTime) }} 
                    </template>
                </Column>

				<Column
					field="EndTime"
					header="Terminate Connection"
				>
					<template #body="slotProps">
						<div v-if="slotProps.data.EndTime =='N/A'">
							<button
								@click="emit('terminateConnection', slotProps.data.RemoteId)"
								class="p-link"
								style="width: fit-content"
							>
								<img
									src="@/assets/activeConnections/cancel_icon.svg"
									alt="user icon"
									class="h-20 w-10"
								/>
							</button>
						</div>
					</template>
				</Column>

				<!-- Empty and Loading Templates -->
				<template #empty>No connections found.</template>
				<template #loading
					><Loading :typeOfData="`${props.type} Connections`" />
				</template>
			</DataTable>
		</div>
	</div>
</template>
<style scoped>
	.custom-button {
		display: flex;
		align-items: center;
		justify-content: center;
		border: none;
		border-radius: 4px; /* Rounded corners */
		padding: 10px 15px; /* Padding around the text */
		cursor: pointer; /* Cursor changes to pointer on hover */
		transition:
			background-color 0.3s,
			transform 0.2s; /* Transition for smooth effects */
	}

	.custom-button:hover {
		transform: scale(0.97); /* Slightly shrink the button when clicked */
	}

	.custom-button:active {
		transform: scale(0.95); /* Slightly shrink the button when clicked */
	}
</style>
