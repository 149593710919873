<script setup lang="ts">
	import ListProject from "@/components/UMC/ListProject.vue";
	import {
		useHttp,
		useProjectListByUser,
		useDeleteUserMutation,
	} from "@/composables/umc-api";

	import { ConfirmDialog } from "@jca/libs/confirm";
	import { computed, ref } from "vue";
	import PageHeader from "@/components/Navigation/PageHeader.vue";
	import { useAuth } from "@/composables/api/auth";

	const { user } = useAuth();
	const http = useHttp();
	const confirmDialog = ref<InstanceType<typeof ConfirmDialog> | null>(null);
	const userDelete = useDeleteUserMutation(http);

	const { isLoading: isProjectLoading, data: projects } =
		useProjectListByUser(http);

	//Compute
	const isLoading = computed(() => {
		return isProjectLoading.value;
	});

	async function deleteUser(projectId: string) {
		const result = await confirmDialog.value?.open({
			title: "Confirm delete",
			text: `Are you sure you want to be removed from the project id: ${projectId}?`,
			okText: "Delete",
			waitOnConfirm: true,
		});
		if (!result) return;

		await userDelete.mutateAsync({
			email: user.value?.email ?? "",
			projectId: projectId,
		});
		result();
	}
</script>

<template>
	<PageHeader title="My Subscriptions" />

	<div class="box-border flex min-h-0 flex-col gap-4 pl-5">
		<ListProject
			:projects="projects ?? []"
			:owners="[]"
			:loading="isLoading"
			@delete="deleteUser"
			:allow-create="false"
		/>

		<ConfirmDialog
			ref="confirmDialog"
			class="w-200"
		/>

	</div>
</template>
