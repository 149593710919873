<template>
	<i>
		<svg
			width="17"
			height="18"
			viewBox="0 0 17 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.52083 17V15.6667H14.75V2.33333H8.52083V1H14.75C15.0833 1 15.375 1.13333 15.625 1.4C15.875 1.66667 16 1.97778 16 2.33333V15.6667C16 16.0222 15.875 16.3333 15.625 16.6C15.375 16.8667 15.0833 17 14.75 17H8.52083ZM7.375 12.8889L6.47917 11.9333L8.60417 9.66667H1V8.33333H8.5625L6.4375 6.06667L7.33333 5.11111L11 9.02222L7.375 12.8889Z"
				fill="#0F384D"
				stroke="#0F384D"
			/>
		</svg>
	</i>
</template>
