<script setup lang="ts">
	import { UserLevel, type FlatUser, type Group } from "@/composables/umc-api";
	import { Dropdown } from "@jca/libs/ui";
	import DataTable from "primevue/datatable";
	import type { DataTableFilterMetaData } from "primevue/datatable";
	import Column from "primevue/column";
	import Search from "@/components/Search.vue";
	import { capitalizeFirstLetter } from "@/composables/utils";
	import { computed, ref, watchEffect } from "vue";
	const props = defineProps<{
		loading: boolean;
		fetching: boolean;
		userType: string;
		users: FlatUser[];
		lastPage: boolean;
	}>();

	const page = defineModel<number>("page", { required: true });
	const pageSize = defineModel<number>("pageSize", { required: true });
	let searchPlaceholder = ref<string>("");
	let searchFields = ref<string[]>([""]);
	//check if the user is not a subscriber
	const isNotSubscriber = computed(
		() => props.userType !== UserLevel.Subscriber,
	);

	const emit = defineEmits<{
		resend: [user: FlatUser];
		edit: [user: FlatUser];
		delete: [user: FlatUser];
		add: [];
	}>();

	// this holds the filter values from primevue table
	const filters = ref<{ global: DataTableFilterMetaData }>({
		global: {
			value: null,
			matchMode: "contains",
		},
	});
	watchEffect(() => {
		searchPlaceholder.value = "Search...";
		searchFields.value = ["Email"];
	});
</script>

<template>
	<div class="box-border flex min-h-0 flex-col gap-4 p-4">
		<div
			class="customizedPrimeVueTable flex grid grid-cols-4 flex-col gap-2 gap-4 overflow-y-auto"
		>
			<DataTable
				tableStyle="min-width: 100%"
				:filters="filters"
				:value="users"
				:loading="loading"
				:globalFilterFields="searchFields"
				class="col-span-5"
			>
				<template #header>
					<div class="mb-5 flex justify-between">
						<Search :filters-prop="filters" />
						<button
							class="mb-2 mr-2 flex items-center rounded bg-bright-orange px-4 py-2 font-bold text-dark-orange md:mb-0"
							@click="emit('add')"
						>
							<span>
								Add
								{{
									capitalizeFirstLetter(userType).substring(
										0,
										userType.length - 1,
									)
								}}
							</span>
						</button>
					</div>
				</template>
				<!-- Columns -->
				<template>
					<Column
						field="Email"
						header="User Email"
						sortable
					/>

					<Column
						v-if="isNotSubscriber"
						field="UserStatus"
						header="Status"
						sortable
					>
						<template #body="{ data }">
							<div class="flex h-24 w-full items-center justify-center">
								{{
									data.UserStatus == "FORCE_CHANGE_PASSWORD"
										? "Pending"
										: "Activated"
								}}
							</div>
						</template>
					</Column>

					<Column
						v-if="isNotSubscriber"
						field="Enabled"
						header="Enabled"
						sortable
					>
						<template #body="{ data }">
							<div class="flex h-24 w-full items-center justify-center">
								{{ data.Enabled ? "Yes" : "No" }}
							</div>
						</template>
					</Column>

					<Column
						field="UserStatus"
						header="Actions"
					>
						<template #body="{ data }">
							<div class="flex h-24 w-full items-center justify-center">
								<!-- Temporary hide the these feature for demo -->
								<!-- <button
									v-if="isNotSubscriber"
									:title="'Edit'"
									class="button icon-button button-secondary"
									@click="$emit('edit', data)"
								>
									<i class="material-icons">edit</i>
								</button> -->
								
								<button
									v-if="!isNotSubscriber"
									:title="'Unsubscribe'"
									class="button icon-button button-error"
									@click="$emit('delete', data)"
								>
									<i class="material-icons">unsubscribe</i>
								</button>
								<button
									v-if="
										data.UserStatus == 'FORCE_CHANGE_PASSWORD' &&
										isNotSubscriber
									"
									:text="'Force password change'"
									class="button icon-button button-warning"
									@click="$emit('resend', data)"
								>
									<i class="material-icons">lock_reset</i>
								</button>
							</div>
						</template>
					</Column>
				</template>
				<template #empty>No user found.</template>
			</DataTable>

			<div
				id="no_svg"
				class="absolute bottom-4 flex w-full items-center justify-center"
			>
				<div class="flex items-center space-x-4">
					<div class="flex items-center space-x-2">
						<button
							:disabled="page === 1 || props.fetching"
							class="button"
							:title="'Previous page'"
							@click="page--"
						>
							<i class="material-icons">chevron_left</i>
						</button>
						<div
							class="text-jca-dark-blue flex flex-1 flex-col items-center justify-center"
						>
							<span class="body-2-bold">Page: {{ page }}</span>
						</div>
						<button
							:disabled="props.lastPage || props.fetching"
							class="button"
							:title="'Next page'"
							@click="page++"
						>
							<i class="material-icons">chevron_right</i>
						</button>
					</div>
					<div class="flex items-center">
						<Dropdown
							label="Row per page"
							v-model.number="pageSize"
						>
							<option value="10">10</option>
							<option value="20">20</option>
							<option value="30">30</option>
						</Dropdown>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
