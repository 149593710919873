<template>
	<i class="flex h-12 w-12 items-center justify-center">
		<svg
			id="Layer_3"
			data-name="Layer 3"
			xmlns="http://www.w3.org/2000/svg"
			width="200"
			height="200"
			viewBox="0 0 200 200"
		>
			<path
				d="M99.85,150.55l-50.17-50.39,13.75-13.75,26.45,26.61v-63.1h19.67v63.1l26.61-26.55,13.93,13.83-50.25,50.25Z"
				style="fill: #10384c; stroke-width: 0px"
			/>
			<path
				d="M149.06,37.4c-5.83-4.3-12.03-7.82-18.61-10.57-6.58-2.74-13.39-4.63-20.43-5.66V0c9.96,1,19.5,3.36,28.62,7.06,9.12,3.71,17.58,8.71,25.36,15.02l-14.95,15.3Z"
				style="fill: #27c2eb; stroke-width: 0px"
			>
				<animate
					attributeType="CSS"
					attributeName="visibility"
					values="visible; hidden; visible; hidden;"
					keyTimes="0; 0.0625; 0.5625; 1"
					dur="2s"
					repeatCount="indefinite"
				/>
			</path>
			<path
				d="M178.37,90.36c-.95-7.03-2.74-13.86-5.39-20.49-2.65-6.63-6.15-12.79-10.51-18.47l15.51-14.49c6.39,7.66,11.44,15.99,15.14,24.99,3.71,9,5.95,18.48,6.72,28.46h-21.48Z"
				style="fill: #27c2eb; stroke-width: 0px"
			>
				<animate
					attributeType="CSS"
					attributeName="visibility"
					values="visible; hidden; visible; hidden;"
					keyTimes="0; 0.125; 0.625; 1"
					dur="2s"
					repeatCount="indefinite"
				/>
			</path>
			<path
				d="M177.42,163.45l-14.95-14.87c4.36-5.68,7.87-11.84,10.53-18.47,2.67-6.63,4.47-13.46,5.42-20.49h21.44c-1.24,9.96-3.67,19.54-7.29,28.73-3.62,9.19-8.67,17.56-15.15,25.1Z"
				style="fill: #27c2eb; stroke-width: 0px"
			>
				<animate
					attributeType="CSS"
					attributeName="visibility"
					values="visible; hidden; visible; hidden;"
					keyTimes="0; 0.1875; 0.6875; 1"
					dur="2s"
					repeatCount="indefinite"
				/>
			</path>
			<path
				d="M109.95,199.99v-21.13c7.04-.93,13.85-2.8,20.42-5.6,6.57-2.8,12.7-6.38,18.38-10.72l15.26,15.52c-7.74,6.33-16.19,11.42-25.35,15.26-9.16,3.84-18.73,6.06-28.72,6.67Z"
				style="fill: #27c2eb; stroke-width: 0px"
			>
				<animate
					attributeType="CSS"
					attributeName="visibility"
					values="visible; hidden; visible; hidden;"
					keyTimes="0; 0.25; 0.75; 1"
					dur="2s"
					repeatCount="indefinite"
				/>
			</path>
			<path
				d="M90.05,199.99v-21.13c-7.04-.93-13.85-2.8-20.42-5.6-6.57-2.8-12.7-6.38-18.38-10.72l-15.26,15.52c7.74,6.33,16.19,11.42,25.35,15.26,9.16,3.84,18.73,6.06,28.72,6.67Z"
				style="fill: #27c2eb; stroke-width: 0px"
			>
				<animate
					attributeType="CSS"
					attributeName="visibility"
					values="visible; hidden; visible; hidden;"
					keyTimes="0; 0.3125; 0.8125; 1"
					dur="2s"
					repeatCount="indefinite"
				/>
			</path>
			<path
				d="M22.58,163.45l14.95-14.87c-4.36-5.68-7.87-11.84-10.53-18.47-2.67-6.63-4.47-13.46-5.42-20.49H.13c1.24,9.96,3.67,19.54,7.29,28.73,3.62,9.19,8.67,17.56,15.15,25.1Z"
				style="fill: #27c2eb; stroke-width: 0px"
			>
				<animate
					attributeType="CSS"
					attributeName="visibility"
					values="visible; hidden; visible; hidden;"
					keyTimes="0; 0.375; 0.875; 1"
					dur="2s"
					repeatCount="indefinite"
				/>
			</path>
			<path
				d="M21.63,90.36c.95-7.03,2.74-13.86,5.39-20.49,2.65-6.63,6.15-12.79,10.51-18.47l-15.51-14.49c-6.39,7.66-11.44,15.99-15.14,24.99C3.17,70.9.93,80.39.15,90.36h21.48Z"
				style="fill: #27c2eb; stroke-width: 0px"
			>
				<animate
					attributeType="CSS"
					attributeName="visibility"
					values="visible; hidden; visible; hidden;"
					keyTimes="0; 0.4375; 0.9375; 1"
					dur="2s"
					repeatCount="indefinite"
				/>
			</path>
			<path
				d="M50.94,37.4c5.83-4.3,12.03-7.82,18.61-10.57,6.58-2.74,13.39-4.63,20.43-5.66V0c-9.96,1-19.5,3.36-28.62,7.06-9.12,3.71-17.58,8.71-25.36,15.02l14.95,15.3Z"
				style="fill: #27c2eb; stroke-width: 0px"
			>
				<animate
					attributeType="CSS"
					attributeName="visibility"
					values="visible; hidden; visible;"
					keyTimes="0; 0.5; 1"
					dur="2s"
					repeatCount="indefinite"
				/>
			</path>
		</svg>
	</i>
</template>