<script setup lang="ts">
	import useVuelidate from "@vuelidate/core";
	import { email, required } from "@vuelidate/validators";
	import { reactive, ref } from "vue";
	import { useAuth } from "../../composables/api/auth";
	import { TextInput } from "@jca/libs/ui";
	import LoginIcon from "./LoginIcon.vue";
	import PasswordIcon from "./PasswordIcon.vue";
	import UserIcon from "./UserIcon.vue";

	const emit = defineEmits<{
		(e: "loggedIn"): void;
		(e: "forgotPassword"): void;
		(e: "resetPassword", email: string): void;
	}>();

	const props = defineProps<{
		apiUrl: string;
	}>();

	const { signIn } = useAuth();

	const signInInProgress = ref(false);
	const error = ref("");

	const completeNewPassword =
		ref<(password: string, api: string) => Promise<void>>();

	const formData = reactive({
		email: "",
		password: "",
	});

	const rules = {
		email: { required, email },
		password: { required },
	};

	const validation = useVuelidate(rules, formData);

	async function handleSignIn() {
		validation.value.$validate();
		if (validation.value.$error) return;

		signInInProgress.value = true;
		validation.value.$reset();
		error.value = "";
		try {
			const result = await signIn(formData.email, formData.password);
			if ("completeNewPassword" in result) {
				completeNewPassword.value = result.completeNewPassword;
				formData.password = "";
			} else emit("loggedIn");
		} catch (e) {
			if ((e as Error).name === "PasswordResetRequiredException") {
				emit("resetPassword", formData.email);
			} else {
				error.value = (e as Error).message;
			}
		}
		signInInProgress.value = false;
	}

	async function handleSetPassword() {
		await validation.value.$validate();
		if (validation.value.$error) return;

		validation.value.$reset();
		error.value = "";
		signInInProgress.value = true;
		try {
			await completeNewPassword.value?.(formData.password, props.apiUrl);
			emit("loggedIn");
		} catch (e) {
			error.value = (e as Error).message;
		}
		signInInProgress.value = false;
	}
</script>

<template>
	<p v-if="completeNewPassword">
		To finish creating your user, enter a new password
	</p>
	<TextInput
		v-model="formData.email"
		:disabled="!!completeNewPassword"
		:placeholder="'Email'"
		type="email"
		autocomplete="email"
		:error="validation.email.$errors?.[0]?.$message"
		@blur="validation.email.$touch"
		class="min-w-0"
	>
		<template #prepend>
			<UserIcon />
		</template>
	</TextInput>
	<TextInput
		v-if="!completeNewPassword"
		v-model="formData.password"
		type="password"
		autocomplete="current-password"
		:placeholder="'Password...'"
		:error="validation.password.$errors?.[0]?.$message || error"
		@blur="validation.password.$touch"
		class="min-w-0"
	>
		<template #prepend>
			<PasswordIcon />
		</template>
	</TextInput>
	<TextInput
		v-else
		v-model="formData.password"
		type="password"
		autocomplete="new-password"
		:placeholder="'New password'"
		:error="validation.password.$errors?.[0]?.$message || error"
		@blur="validation.password.$touch"
		class="min-w-0"
	>
		<template #prepend>
			<PasswordIcon />
		</template>
	</TextInput>
	<template v-if="!completeNewPassword">
		<button
			class="button button-primary"
			@click="handleSignIn"
			:disabled="signInInProgress"
		>
			<LoginIcon />
			<span>Login</span>
		</button>
		<button
			class="button"
			:disabled="signInInProgress"
			@click="emit('forgotPassword')"
		>
			<span>Forgot password?</span>
		</button>
	</template>
	<button
		v-else
		class="button button-primary"
		@click="handleSetPassword"
		:disabled="signInInProgress"
	>
		<span>Save password</span>
	</button>
</template>

<style scoped>
	.error {
		color: var(--theme-error);
		text-align: end;
		margin-bottom: 1rem;
	}
</style>
