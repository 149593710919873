<script setup lang="ts">
    import { computed, defineProps } from 'vue';
    const props = defineProps<{
        actionText: string;
        imgSrc: string;
    }>();

    const emit = defineEmits<{
		action: [];
	}>();

    // Computed property to generate the full image path
    const computedImageSrc = computed(() => new URL(`../assets/activeConnections/${props.imgSrc}.svg`, import.meta.url).href);
</script>

<template>
    <button class="mb-6 mr-2 flex items-center rounded bg-bright-orange px-6 font-bold text-dark-orange"
            @click="$emit('action')">
            <img :src="computedImageSrc" class="w-6 mr-2" />
            <span> {{ props.actionText }} </span>
    </button>
</template>