<script setup lang="ts">
	import AddMachine from "@/components/RemoteAccess/AddMachine.vue";
	import Download from "@/components/RemoteAccess/Download.vue";
	import ListMachine from "@/components/RemoteAccess/ListMachine.vue";
	import MachineDownloadCertificate from "@/components/RemoteAccess/MachineDownloadCertificate.vue";
	import { AsyncDialog } from "@jca/libs/confirm";
	import {
		MachineCertificateStatus,
		useAddMachineMutation,
		useDeleteMachineCertificateMutation,
		useMachineCertificateHttp,
		useMachineList,
		useMoveMachineToProjectMutation,
		useSetMachineCertificateStatusMutation,
		type Machine,
		type MachineCredentialCertificateDto,
		type MachineCredentialDto,
	} from "@/composables/machie-certificate-api";
	import { useGetProject, useHttp } from "@/composables/umc-api";

	import { computed, ref } from "vue";
	import { useRouter } from "vue-router";
	import PageHeader from "@/components/Navigation/PageHeader.vue";
	import { Project } from "../../composables/api-models";
	import EditMachine from "/src/components/RemoteAccess/EditMachine .vue";
	import SetMachine from "/src/components/RemoteAccess/SetMachine.vue";

	const router = useRouter();

	// get the project id from the route
	const props = defineProps<{
		projectId: string;
	}>();

	//Dialogs
	const addDialog = ref<InstanceType<typeof AsyncDialog>>();
	const deleteMachineDialog = ref<InstanceType<typeof AsyncDialog>>();
	const createCertificateDialog = ref<InstanceType<typeof AsyncDialog>>();
	const moveMachineDialog = ref<InstanceType<typeof AsyncDialog>>();
	const downloadCertificateDialog =
		ref<InstanceType<typeof MachineDownloadCertificate>>();
	const setMachineCertificateStatusDialog =
		ref<InstanceType<typeof AsyncDialog>>();

	const downloadDialog = ref<InstanceType<typeof Download>>();

	const http = useHttp();
	const machineCertificateHttp = useMachineCertificateHttp();

	//Queries
	const response = useMachineList(
		machineCertificateHttp,
		computed(() => props.projectId ?? ""),
	);

	const project = useGetProject(
		http,
		computed(() => props.projectId),
	);

	//Mutations
	const createCertMutation = useAddMachineMutation(machineCertificateHttp);
	const deleteMachineMutation = useDeleteMachineCertificateMutation(
		machineCertificateHttp,
	);
	const moveMachineToProjectMutation = useMoveMachineToProjectMutation(
		machineCertificateHttp,
	);

	const setMachineCertificateStatusMutation =
		useSetMachineCertificateStatusMutation(machineCertificateHttp);

	//refs
	const machineSelected = ref<Machine>();
	const machineCredentail = ref<MachineCredentialCertificateDto>();

	function addMachine() {
		createCertMutation.reset();
		addDialog.value?.open<void, { machineName: string }>({
			callback: async result => {
				try {
					const response = await createCertMutation.mutateAsync({
						machineName: result.machineName,
						projectId: props.projectId,
					});
					downloadCertificateDialog.value?.openDialog(response.data);
					return true;
				} catch (error) {
					console.error(error);
					return false;
				}
			},
		});
	}

	function downloadCertificate(item: MachineCredentialDto) {
		try {
			var machineCredentialCertificateDto = {
				ProjectId: item.ProjectId,
				MachineId: item.MachineId,
				MachineName: item.MachineName,
				ZipFilePath: item.ZipFilePath,
				DebianFilePath: item.DebianFilePath,
			} as MachineCredentialCertificateDto;

			downloadCertificateDialog.value?.openDialog(
				machineCredentialCertificateDto,
			);
			return true;
		} catch (error) {
			console.error(error);
			return false;
		}
	}

	function createCertificate(item: Machine) {
		machineSelected.value = item;
		createCertMutation.reset();
		createCertificateDialog.value?.open<
			void,
			{
				machine: Machine;
			}
		>({
			callback: async result => {
				try {
					const response = await createCertMutation.mutateAsync({
						machineName: result.machine.MachineName,
						projectId: props.projectId,
						machineId: result.machine.MachineId,
					});
					downloadCertificateDialog.value?.openDialog(response.data);
					return true;
				} catch (error) {
					console.error(error);
					return false;
				}
			},
		});
	}

	function moveMachine(item: Machine) {
		machineSelected.value = item;
		moveMachineToProjectMutation.reset();
		moveMachineDialog.value?.open<void, { newProjectId: string, organizationId:string, organizationName:string}>({
			callback: async result => {
				try {
					const response = await moveMachineToProjectMutation.mutateAsync({
						projectId: props.projectId,
						machineId: item.MachineId!,
						newProjectId: result.newProjectId!,
						organizationId: result.organizationId!,
						organizationName: result.organizationName!,

					});
					return true;
				} catch (error) {
					console.error(error);
					return false;
				}
			},
		});
	}

	function deleteMachine(item: MachineCredentialDto) {
		var machine = {
			ProjectId: item.ProjectId,
			MachineId: item.MachineId,
			MachineName: item.MachineName,
		} as Machine;
		machineSelected.value = machine;
		deleteMachineMutation.reset();
		deleteMachineDialog.value?.open<void, Machine>({
			callback: async result => {
				await deleteMachineMutation.mutateAsync({
					projectId: props.projectId,
					machineId: result.MachineId.toString(),
				});
				return true;
			},
		});
	}

	function setMachineCertificateStatus(item: MachineCredentialDto) {
		setMachineCertificateStatusMutation.reset();
		var machine = {
			ProjectId: item.ProjectId,
			MachineId: item.MachineId,
			MachineName: item.MachineName,
			CertificateStatus: item.CertificateStatus,
		} as Machine;
		machineSelected.value = machine;
		setMachineCertificateStatusDialog?.value?.open<Machine, Machine>({
			callback: async result => {
				await setMachineCertificateStatusMutation.mutateAsync({
					projectId: props.projectId,
					machineId: result.MachineId.toString(),
					enabled: result.CertificateStatus === MachineCertificateStatus.Active,
				});
				return true;
			},
		});
	}

	function viewHistroy(item: Machine) {
		router.push({
			name: "history",
			params: {
				machineId: item.MachineId,
				machineName: item.MachineName,
			},
		});
	}
</script>

<template>
	<PageHeader
		:title="project.data.value?.Name + ': Machines'"
		:back="true"
	/>

	<div class="box-border flex min-h-0 flex-col gap-4 pl-5">
		<ListMachine
			:machines="response.data.value as Machine[]"
			:project="project.data.value as Project"
			:loading="response.isLoading.value"
			@add="addMachine"
			@deleteMachine="deleteMachine"
			@downloadCertificate="downloadCertificate"
			@create="createCertificate"
			@moveMachine="moveMachine"
			@setMachineCertificateStatus="setMachineCertificateStatus"
			@view-histroy="viewHistroy"
		/>

		<AsyncDialog
			ref="addDialog"
			v-slot="slotProps"
		>
			<AddMachine
				:loading="slotProps.loading"
				@close="slotProps.close($event)"
				:error="createCertMutation.error.value?.toString()"
			/>
		</AsyncDialog>

		<AsyncDialog
			ref="moveMachineDialog"
			v-slot="slotProps"
		>
			<EditMachine
				:loading="slotProps.loading"
				:machine="machineSelected!"
				@close="slotProps.close($event)"
				:error="moveMachineToProjectMutation.error.value?.toString()"
			/>
		</AsyncDialog>

		<AsyncDialog
			ref="deleteMachineDialog"
			v-slot="slotProps"
		>
			<DeleteMachine
				:loading="slotProps.loading"
				:machine="machineSelected!"
				@close="slotProps.close($event)"
				:error="deleteMachineMutation.error.value?.toString()"
			/>
		</AsyncDialog>
		<AsyncDialog
			ref="setMachineCertificateStatusDialog"
			v-slot="slotProps"
		>
			<SetMachine
				:loading="slotProps.loading"
				:machine="machineSelected!"
				@close="slotProps.close($event)"
				:error="setMachineCertificateStatusMutation.error.value?.toString()"
			/>
		</AsyncDialog>

		<AsyncDialog
			ref="createCertificateDialog"
			v-slot="slotProps"
		>
			<CreateCertificate
				:operation="'create'"
				:loading="slotProps.loading"
				:machine="machineSelected!"
				@close="slotProps.close($event)"
				:error="createCertMutation.error.value?.toString()"
			/>
		</AsyncDialog>

		<AsyncDialog
			ref="updateMachineDialog"
			v-slot="slotProps"
		>
			<CreateCertificate
				:operation="'create'"
				:loading="slotProps.loading"
				:machine="machineSelected!"
				@close="slotProps.close($event)"
				:error="createCertMutation.error.value?.toString()"
			/>
		</AsyncDialog>

		<MachineDownloadCertificate
			:credential="machineCredentail"
			ref="downloadCertificateDialog"
		/>

		<Download ref="downloadDialog" />
	</div>
</template>
