import {
	keepPreviousData,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/vue-query";
import { computed, ref, type ComputedRef, type Ref } from "vue";
import type {
	AdminGetUserResponse,
	Api,
	ListUsersInGroupResponse,
	ListUsersWithGroupsResponse,
	UserType,
	UserWithGroups,
} from "../api-models";


export enum GroupType {
	PortalAdmin = "PortalAdmin",
	ProjectOwner = "ProjectOwner",
	Admin = "Admin",
	User = "User",
	Manager = "Manager",
	Planner = "Planner",
	Operator = "Operator",
}

export type Group =
	| GroupType.PortalAdmin
	| GroupType.ProjectOwner
	| GroupType.User
	| GroupType.Manager
	| GroupType.Planner
	| GroupType.Operator;

export enum UserLevel {
	User= "users",
	Subscriber= "subscribers",
}



export type SelectableGroup = GroupType.Manager | GroupType.Planner | GroupType.Operator;

export interface FlatUser {
	Enabled: boolean;
	UserCreateDate: string;
	UserLastModifiedDate: string;
	Username: string;
	UserStatus: string;
	EmailVerified: boolean;
	Email: string;
	Groups: Group[];
	ProjectId?: string;
	SelectedGroup?: Group;
	DisplayGroups: string;
}

export interface Attribute {
	Name: string;
	Value: string;
}

export interface UserStatus {
	Value: string;
}

function userAttribute(
	user: UserType | AdminGetUserResponse,
	attribute: string,
): string {
	if ("Attributes" in user) {
		return (
			user.Attributes?.find(value => value.Name === attribute)?.Value ?? ""
		);
	} else if ("UserAttributes" in user) {
		return (
			user.UserAttributes?.find(value => value.Name === attribute)?.Value ?? ""
		);
	} else {
		return "";
	}
}

function generateFlatUserFromUserWithGroups(user: UserWithGroups): FlatUser {
	return {
		Username: user.User?.Username ?? "",
		Enabled: user.User?.Enabled ?? false,
		UserCreateDate: user.User?.UserCreateDate ?? new Date().toISOString(),
		UserLastModifiedDate:
			user.User?.UserLastModifiedDate ?? new Date().toISOString(),
		UserStatus: user.User?.UserStatus?.Value ?? "",
		Email: user.User ? userAttribute(user.User, "email") : "",
		EmailVerified: user.User
			? userAttribute(user.User, "email_verified") == "true"
			: false,
		DisplayGroups: user.Groups?.join(", ") ?? "",
		Groups: user.Groups as Group[],
		SelectedGroup: user.Groups?.find(
			group =>
				group === GroupType.Manager || group === GroupType.Manager || group === GroupType.Operator,
		) as Group | undefined,
	};
}

function generateFlatUserFromUserType(
	user: UserType,
	groupName: string,
): FlatUser {
	return {
		Username: user?.Username ?? "",
		Enabled: user?.Enabled ?? false,
		UserCreateDate: user?.UserCreateDate ?? new Date().toISOString(),
		UserLastModifiedDate:
			user?.UserLastModifiedDate ?? new Date().toISOString(),
		UserStatus: user?.UserStatus?.Value ?? "",
		Email: user ? userAttribute(user, "email") : "",
		ProjectId: user ? userAttribute(user, "custom:ProjectId") : "",
		EmailVerified: user
			? userAttribute(user, "email_verified") == "true"
			: false,
		DisplayGroups: groupName,
		Groups: [groupName] as Group[],
		SelectedGroup: groupName as Group,
	};
}

export function useUserList(
	api: Api<unknown>,
	page: Ref<number>,
	itemsPerPage: Ref<number>,
) {
	const pageTokens = new Map<number, string>();
	const lastPage = ref(false);

	function convertCognitoUserToFlatUser(
		userRequest: ListUsersWithGroupsResponse,
	): FlatUser[] {
		const flatUsers: Array<FlatUser> = [];
		userRequest.UsersWithGroups?.forEach(user => {
			const flatUser: FlatUser = generateFlatUserFromUserWithGroups(user);
			flatUsers.push(flatUser);
		});

		return flatUsers;
	}

	const userListQuery = async (itemsPerPage: number, page: number) => {
		const token = pageTokens.get(page)?.toString() ?? "";
		const res = await api.umc.usersList({
			limit: itemsPerPage,
			paginationToken: token,
		});

		if (!pageTokens.has(page + 1) && res.data.PaginationToken)
			pageTokens.set(page + 1, res.data.PaginationToken);

		lastPage.value = !pageTokens.has(page + 1);

		return convertCognitoUserToFlatUser(res.data);
	};

	const query = useQuery({
		queryKey: ["userList", itemsPerPage, page],
		queryFn: () => userListQuery(itemsPerPage.value, page.value),
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});

	return { ...query, lastPage };
}

export function useUserListByGroup(
	api: Api<unknown>,
	groupName: Ref<Group>,
	projectId: Ref<string>,
	page: Ref<number>,
	itemsPerPage: Ref<number>,
) {
	const pageTokens = new Map<number, string>();
	const lastPage = ref(false);

	function convertCognitoUserToFlatUser(
		userRequest: ListUsersInGroupResponse,
	): FlatUser[] {
		const flatUsers: FlatUser[] = [];
		userRequest.Users?.forEach(user => {
			const flatUser: FlatUser = generateFlatUserFromUserType(
				user,
				groupName.value,
			);
			flatUsers.push(flatUser);
		});

		return flatUsers;
	}

	const userListByGroupQuery = async (
		groupName: string,
		projectId: string,
		itemsPerPage: number,
		page: number,
	) => {
		const token = pageTokens.get(page)?.toString() ?? "";
		const res = await api.umc.getAllUsersGroup({
			groupName: groupName,
			projectId: projectId,
			limit: itemsPerPage,
			nextToken: token,
		});

		if (!pageTokens.has(page + 1) && res.data.NextToken)
			pageTokens.set(page + 1, res.data.NextToken);

		lastPage.value = !pageTokens.has(page + 1);

		return convertCognitoUserToFlatUser(res.data);
	};

	const query = useQuery<FlatUser[]>({
		queryKey: ["userList", groupName, projectId, itemsPerPage, page],
		queryFn: async () =>
			await userListByGroupQuery(
				groupName.value,
				projectId.value,
				itemsPerPage.value,
				page.value,
			),
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});

	return { ...query, lastPage };
}

export const useUserQuery = (
	http: Api<unknown>,
	userId: Ref<string> | ComputedRef<string>,
	projectId: Ref<string>,
) => {
	const hasUserId = computed(() => !!userId.value);
	return useQuery({
		queryKey: ["user", userId],
		queryFn: async () => {
			const response = await http.umc.userList({
				userName: userId.value,
				projectId: projectId.value,
			});
			return generateFlatUserFromUserWithGroups(response.data);
		},
		enabled: hasUserId,
	});
};

export const useReinviteUserMutation = (api: Api<unknown>) => {
	const queryClient = useQueryClient();
	// UseMutation expects a single argument, so wrap the parameters in an object
	return useMutation({
		mutationFn: async ({
			email,
			projectId,
		}: {
			email: string;
			projectId: string;
		}) => {
			await api.umc.userReinviteUpdate({
				userName: email,
				projectId: projectId,
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["userList"] });
		},
	});
};

export const useUpdateUserMutation = (api: Api<unknown>) => {
	const queryClient = useQueryClient();
	return useMutation<void, string, FlatUser, unknown>({
		mutationFn: async (editedUser: FlatUser) => {
			const groups = editedUser.Groups.filter(
				value =>
					value !== GroupType.Manager && value !== GroupType.Planner && value !== GroupType.Operator,
			) as Group[];
			if (editedUser.SelectedGroup) groups.push(editedUser.SelectedGroup);
			await api.umc
				.userUpdate({
					userName: editedUser.Email,
					Groups: groups,
				})
				.catch(e => {
					throw e.error as string;
				});
		},
		onSuccess: () => queryClient.invalidateQueries({ queryKey: ["userList"] }),
	});
};

export const useUpdateEnableUserMutation = (api: Api<unknown>) => {
	const queryClient = useQueryClient();
	return useMutation<
		void,
		string,
		{
			email: string;
			enabled: boolean;
			projectId: string;
		},
		unknown
	>({
		mutationFn: async ({
			email,
			enabled,
			projectId,
		}: {
			email: string;
			enabled: boolean;
			projectId: string;
		}) => {
			const response = enabled
				? api.umc.userEnableUpdate({ userName: email, projectId: projectId })
				: api.umc.userDisableUpdate({ userName: email, projectId: projectId });
			await response.catch(e => {
				throw e.error as string;
			});
		},
		onSuccess: () => queryClient.invalidateQueries({ queryKey: ["userList"] }),
	});
};

export const useAddUserMutation = (api: Api<unknown>) => {
	const queryClient = useQueryClient();
	return useMutation<
		void,
		string,
		{
			email: string;
			selectedGroup: string;
			projectId: string;
		},
		unknown
	>({
		mutationFn: async ({
			email,
			selectedGroup,
			projectId,
		}: {
			email: string;
			selectedGroup: string;
			projectId: string;
		}) => {
			await api.umc
				.userCreate({
					userName: email,
					groupName: selectedGroup,
					projectId: projectId,
				})
				.catch(e => {
					throw e.error as string;
				});
		},
		onSuccess: () => queryClient.invalidateQueries({ queryKey: ["userList"] }),
	});
};

export const useDeleteUserMutation = (api: Api<unknown>) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async ({
			email,
			projectId,
		}: {
			email: string;
			projectId: string;
		}) => {
			await api.umc.userDelete({
				userName: email,
				projectId: projectId,
			});
		},
		onSuccess: () => queryClient.invalidateQueries({ queryKey: ["userList"] }),
	});
};

export const useActivateUserMutation = (api: Api<unknown>) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async ({
			email,
		}: {
			email: string;
		}) => {
			await api.umc.userActivateUpdate({
				userName: email,
			});
		},
		onSuccess: () => queryClient.invalidateQueries({ queryKey: ["userList"] }),
	});
};