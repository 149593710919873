<script setup lang="ts">
	import { ref } from "vue";
	import { useRoute, useRouter } from "vue-router";
	//TODO: package causes error
	// The requested module '/node_modules/.pnpm/zen-observable@0.8.15/node_modules/zen-observable/index.js?v=4f14ee59' does not provide an export named 'default'
	//import { ForgotPasswordForm, LoginForm, ResetPasswordForm} from "@jca/libs/auth";
	import ForgotPasswordForm from "@/libs/auth/ForgotPasswordForm.vue";
	import LoginForm from "@/libs/auth/LoginForm.vue";
	import ResetPasswordForm from "@/libs/auth/ResetPasswordForm.vue";
	import { emptyGUID } from "@jca/libs/api";
	import { UserLevel } from "/src/composables/umc-api";
	const route = useRoute();
	const router = useRouter();

	const emit = defineEmits<{
		(e: "afterSignIn"): void;
	}>();

	defineProps<{
		apiUrl: string;
		showHeader?: boolean;
	}>();

	type State = "login" | "forgotPassword" | "resetPassword";
	const state = ref<State>("login");

	function login() {
		if (route.query.redirect) {
			if (route.query.redirect == "users") {
				router.push({
					name: "users",
					params: { projectId: emptyGUID, userType: UserLevel.User },
				});
			} else if (route.query.redirect == "machines") {
				router.push({ name: "my_subscriptions" });
			} else {
				//redirect to users page with admin role
				router.push({ name: route.query.redirect as string });
			}
		} else emit("afterSignIn");
	}
	const resetEmail = ref("");
	function reset(email: string) {
		resetEmail.value = email;
		state.value = "resetPassword";
	}
</script>

<template>
	<div
		class="flex min-h-screen w-full flex-col bg-dark-green bg-login-img bg-cover"
	>
		<div class="flex-grow md:flex">
			<div class="relative md:w-1/2">
				<!-- First image -->
				<img
					class="w-full"
					src="@/assets/login/background.svg"
					alt="Background Logo"
				/>

				<!-- Second image -->
				<img
					class="absolute left-1/2 top-1/2 w-1/2 -translate-x-1/2 -translate-y-1/2 transform rounded-lg"
					src="@/assets/login/ptx-logo.svg"
					alt="ptx Trimble Logo"
				/>
			</div>

			<div
				class="mt-4 flex flex-col items-center justify-center md:ml-6 md:mt-0 md:w-1/2"
			>
				<div
					class="mt-4 flex flex-grow flex-col items-center justify-center md:ml-6 md:mt-0 md:w-1/2"
				>
					<form
						class="flex flex-col gap-[1.5rem] bg-dark-green p-10"
						id="authForm"
						@submit.prevent
					>
						<LoginForm
							v-if="state === 'login'"
							:api-url="apiUrl"
							@loggedIn="login"
							@forgotPassword="state = 'forgotPassword'"
							@resetPassword="reset"
						/>
						<ForgotPasswordForm
							v-else-if="state === 'forgotPassword'"
							@done="state = 'login'"
						/>
						<ResetPasswordForm
							v-else
							:email="resetEmail"
							@done="state = 'login'"
						/>
					</form>
				</div>
				<!-- Sticky Footer -->
				<div class="bottom-0 w-full flex-shrink-0 flex-wrap px-4 text-center">
					<p
						class="xs:text-custom-xs font-[700] text-medium-green sm:text-custom-sm md:text-custom-md lg:text-custom-lg"
					>
						PORTAL
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<style>
	#authForm input.text-jca-dark-blue {
		@apply w-[95%] rounded-[2.5rem] pl-10;
	}
	#authForm .error-text {
		@apply mb-[-1.25rem] pl-5 text-bright-orange;
	}
	#authForm button {
		@apply justify-center bg-bright-orange text-center;
	}
	#authForm p {
		@apply w-[15rem] text-center font-bold text-bright-orange;
	}
</style>
