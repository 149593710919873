import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { computed, type Ref } from "vue";
import type {
	Api,
	MachineCredentialDto,
	OrganizationRequest,
} from "../machine-certificate-api-models";
import type { Machine } from "./models";

export function useMachineList(
	api: Api<unknown>,
	projectId: Ref<string | undefined>,
) {
	return useQuery<MachineCredentialDto[]>({
		queryKey: ["machines", projectId.value],
		queryFn: async () => {
			const response = await api.machinecertificate.getMachineCredentials(
				projectId.value!,
			);
			if (response.status !== 200) throw new Error(await response.text());
			return response.data;
		},
		enabled: computed(() => projectId.value !== undefined),
	});
}

export const useAddMachineMutation = (api: Api<unknown>) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async ({
			machineName,
			projectId,
			machineId,
		}: {
			machineName: string;
			projectId: string;
			machineId?: string;
		}) => {
			// eslint-disable-next-line no-debugger
			const request = {
				machineId: machineId ?? crypto.randomUUID(),
				machineName: machineName,
			};

			try {
				return await api.machinecertificate.addMachineCredential(
					projectId,
					request,
				);
			} catch (e: any) {
				throw e.error as string;
			}
		},
		onSuccess: () => queryClient.invalidateQueries({ queryKey: ["machines"] }),
	});
};

export const useRecreateMachineCertificateMutation = (api: Api<unknown>) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async ({ machine }: { machine: Machine }) => {
			try {
				return await api.machinecertificate.recreateCertificate(
					machine.ProjectId,
					machine.MachineId,
					{ machineName: machine.MachineName },
				);
			} catch (e: any) {
				throw e.error as string;
			}
		},
		onSuccess: () => queryClient.invalidateQueries({ queryKey: ["machines"] }), //add account
	});
};

export const useRevokeMachineCertificateMutation = (api: Api<unknown>) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async ({
			projectId,
			machineId,
		}: {
			projectId: string;
			machineId: string;
		}) => {
			try {
				return await api.machinecertificate.revokeCredential(
					projectId,
					machineId,
				);
			} catch (e: any) {
				throw e.error as string;
			}
		},
		onSuccess: () => queryClient.invalidateQueries({ queryKey: ["machines"] }),
	});
};

export const useDeleteMachineCertificateMutation = (api: Api<unknown>) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async ({
			projectId,
			machineId,
		}: {
			projectId: string;
			machineId: string;
		}) => {
			try {
				return await api.machinecertificate.deleteMachine(projectId, machineId);
			} catch (e: any) {
				throw e.error as string;
			}
		},
		onSuccess: () => queryClient.invalidateQueries({ queryKey: ["machines"] }),
	});
};

export const useMoveMachineToProjectMutation = (api: Api<unknown>) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async ({
			projectId,
			machineId,
			newProjectId,
			organizationId,
			organizationName,
		}: {
			projectId: string;
			machineId: string;
			newProjectId: string;
			organizationId: string;
			organizationName: string;
			
		}) => {
		 

			const organizationRequest: OrganizationRequest = {
				organizationId: organizationId,
				organizationName: organizationName,
			};

			try {
				return await api.machinecertificate.moveMachineToProject(
					projectId,
					machineId,
					newProjectId,
					organizationRequest,
				);
			} catch (e: any) {
				throw e.error as string;
			}
		},
		onSuccess: () => queryClient.invalidateQueries({ queryKey: ["machines"] }),
	});
};


export const useSetMachineCertificateStatusMutation = (api: Api<unknown>) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async ({
			projectId,
			machineId,
			enabled ,
		}: {
			projectId: string;
			machineId: string;
			enabled: boolean;
		}) => {
			try {
				return await api.machinecertificate.setMachineCertificate(
					projectId,
					machineId,
					{ enabled },
				);
			} catch (e: any) {
				throw e.error as string;
			}
		},
		onSuccess: () => queryClient.invalidateQueries({ queryKey: ["machines"] }),
	});
};
