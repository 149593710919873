
 export function getDuration(startTime: string, endTime: string) {
    if (endTime === 'N/A' || startTime === 'N/A' || endTime === null || startTime === null) {
        return 'N/A';
    }

    const start = new Date(startTime);

    const end = new Date(endTime);

    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
        return 'Invalid date';
    }
    let diff = Math.abs(end.getTime() - start.getTime());

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    diff %= (1000 * 60 * 60 * 24);

    const hours = Math.floor(diff / (1000 * 60 * 60));
    diff %= (1000 * 60 * 60);

    const minutes = Math.floor(diff / (1000 * 60));
    diff %= (1000 * 60);

    const seconds = Math.floor(diff / 1000);

    if (days === 0 && hours === 0 && minutes === 0) {
        return `${seconds}s`;
    }

    if (days === 0 && hours === 0) {
        return `${minutes}m ${seconds}s`;
    }

    if (days === 0) {
        return `${hours}h ${minutes}m ${seconds}s`;
    }

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
}


