<template>
	<i class="w-12 h-12 flex justify-center items-center">
		<svg
			width="19"
			height="24"
			viewBox="0 0 19 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2.50172 24C1.82772 24 1.24226 23.7516 0.745347 23.2547C0.248449 22.7577 0 22.1723 0 21.4983V10.0893C0 9.38212 0.248449 8.78378 0.745347 8.29432C1.24226 7.80487 1.82772 7.56014 2.50172 7.56014H3.95876V5.52577C3.95876 3.9869 4.48954 2.68121 5.55109 1.60872C6.61266 0.536239 7.89386 0 9.39467 0C10.8955 0 12.1792 0.536239 13.2456 1.60872C14.3121 2.68121 14.8454 3.9869 14.8454 5.52577V7.56014H16.3024C16.984 7.56014 17.5759 7.80487 18.0782 8.29432C18.5805 8.78378 18.8316 9.38212 18.8316 10.0893V21.4983C18.8316 22.1723 18.5805 22.7577 18.0782 23.2547C17.5759 23.7516 16.984 24 16.3024 24H2.50172ZM9.40888 17.8694C9.99082 17.8694 10.4788 17.6713 10.8729 17.2752C11.2669 16.879 11.4639 16.4028 11.4639 15.8465C11.4639 15.3074 11.2646 14.8179 10.866 14.378C10.4674 13.9381 9.97718 13.7182 9.39524 13.7182C8.81331 13.7182 8.32531 13.9376 7.93127 14.3762C7.53723 14.8149 7.34021 15.3065 7.34021 15.851C7.34021 16.403 7.5395 16.8774 7.93809 17.2742C8.33668 17.671 8.82694 17.8694 9.40888 17.8694ZM6.46048 7.56014H12.3436V5.53212C12.3436 4.65733 12.0598 3.93814 11.4922 3.37457C10.9245 2.811 10.2299 2.52921 9.40852 2.52921C8.58712 2.52921 7.89042 2.811 7.31843 3.37457C6.74646 3.93814 6.46048 4.65733 6.46048 5.53212V7.56014Z"
				fill="#0F384D"
			/>
		</svg>
	</i>
</template>
