<script setup lang="ts">
	import PageHeader from "@/components/Navigation/PageHeader.vue";
	import { ref } from "vue";
	import {
		useAuditTrailsActiveConnections,
		useAuditTrailsEndActiveConnection,
	} from "@/composables/audit-trails-api/audit-trails";
	import { useAuditTrailsHttp } from "@/composables/audit-trails-api/http-audit-trails";
	import {
		AuditTrailsType,
		DeleteTaskResponse,
	} from "@/composables/api-models";
	import ListConnection from "@/components/RemoteAccess/ListConnection.vue";
	import { AuditTrailsPageType } from "@/composables/audit-trails-api";
	import { ConfirmDialog } from "@jca/libs/confirm";
	import EndConnection from "@/components/AuditTrails/EndConnection.vue";
	import Disclaimer from "@/components/AuditTrails/Disclaimer.vue";

	// http request to get active connections
	const auditTrailsHttp = useAuditTrailsHttp();
	const activeConnection = useAuditTrailsActiveConnections(auditTrailsHttp);
	const useEndConnection = useAuditTrailsEndActiveConnection(auditTrailsHttp);
	const type = AuditTrailsPageType.Active;
	const confirmDialog = ref<InstanceType<typeof ConfirmDialog> | null>(null);
	const deleteDialog = ref<InstanceType<typeof EndConnection>>();
	const disclaimerDialog = ref<InstanceType<typeof Disclaimer>>();
	const deleteTaskResponse = ref<DeleteTaskResponse>();

	async function TerminateConnection(remoteId: string) {
		remoteId = remoteId.split("/")[1];
		const result = await confirmDialog.value?.open({
			title: "Confirm Terminate Connection",
			text:
				"Are you sure you want to terminate this connection (" +
				remoteId +
				")?",
			okText: "Terminate",
			cancelText: "Cancel",
		});
		if (result) {
			try {
				console.log("Terminate connection: ", remoteId);

				const response = await useEndConnection.mutateAsync({
					remoteId,
				});
				console.log("response", response.data);
				deleteDialog.value?.openDialog(response.data);
				return true;
			} catch (error) {
				console.error(error);
				return false;
			}
		}
	}
	function openDisclaimerDialog() {
		disclaimerDialog.value?.openDialog();
	}
</script>

<template>
	<div class="box-border flex min-h-0 flex-col gap-4 pl-5">
		<div class="flex">
			<PageHeader title="Active ECS Tasks" />
			<button
				@click="openDisclaimerDialog()"
				class="h-10 w-10 bg-bright-orange text-white rounded-full flex items-center justify-center mt-8 mx-5"
			>
				<i class="material-icons">info </i>
			</button>
		</div>
		<ListConnection
			:tasks="activeConnection.data.value as AuditTrailsType[]"
			:type="type"
			:isLoading="activeConnection.isLoading.value"
			@terminateConnection="TerminateConnection"
		/>
		<ConfirmDialog ref="confirmDialog" />
		<EndConnection ref="deleteDialog" />
		<Disclaimer ref="disclaimerDialog" />
	</div>
</template>
