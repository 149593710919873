<script setup lang="ts">
	import ClockSpinnerIcon from "@/icons/ClockSpinnerIcon.vue";
	import { DialogLayout } from "@jca/libs/ui";
	import { ref } from "vue";
    import { Machine, MachineCertificateStatus } from "/src/composables/machie-certificate-api";

	const props = defineProps<{
		machine: Machine;
		loading: boolean;
		error?: string | null;
	}>();

	const emit = defineEmits<{
		close: [machie?: Machine];
	}>();
    console.log(props.machine);
	const enabled = ref(props.machine.CertificateStatus === MachineCertificateStatus.Active);

	function save() {
		emit("close", {
			...props.machine,
			CertificateStatus: enabled.value ? MachineCertificateStatus.Active : MachineCertificateStatus.Inactive,
		});
	}
</script>

<template>
	<DialogLayout class="bg-netural-orange">
		<template #title>
			<span class="font-bold text-dark-green">Current Certificate Status: {{ machine.CertificateStatus == MachineCertificateStatus.Active ? "Active" : "Inactive" }}</span>
		</template>
		<div class="flex">
			<!-- Enable button part -->
			<button
				@click="enabled = true"
				:class="[
					'toggle',
					enabled ? 'text-bright-orange' : 'text-light-orange',
				]"
			>
				Enable
			</button>

			<!-- Disable button part -->
			<button
				@click="enabled = false"
				:class="['toggle', !enabled ? 'text-light-orange' : 'text-bright-orange']"
			>
				Disable
			</button>
		</div>
		<div
			v-if="error"
			class="text-red"
			style="bottom: -1rem"
		>
			{{ error }}
		</div>
		<template #actions>
			<button
				class="button bg-light-orange"
				:disabled="props.loading"
				@click="emit('close')"
			>
				Cancel
			</button>
			<button
				class="button bg-bright-orange"
				:disabled="props.loading"
				@click="save"
			>
				<ClockSpinnerIcon v-if="loading" />
				<span>Save</span>
			</button>
		</template>
	</DialogLayout>
</template>
<style scoped>
	.toggle {
		@apply w-1/2 px-4 py-2 font-bold transition-all duration-300 focus:outline-none;
	}
</style>