<script setup lang="ts">
	import useVuelidate from "@vuelidate/core";
	import {
		maxLength,
		minLength,
		numeric,
		required,
	} from "@vuelidate/validators";
	import { reactive, ref } from "vue";
	import { useAuth } from "../../composables/api/auth";
	import { TextInput } from "@jca/libs/ui";
	import PasswordIcon from "./PasswordIcon.vue";
	import UserIcon from "./UserIcon.vue";

	const props = defineProps<{
		email: string;
	}>();
	const emit = defineEmits<{
		(e: "done"): void;
	}>();

	let error = ref("");

	const formData = reactive({
		otp: "",
		password: "",
	});

	const rules = {
		password: { required },
		otp: {
			required,
			numeric,
			minLength: minLength(6),
			maxLength: maxLength(6),
		},
	};

	const validation = useVuelidate(rules, formData);
	const submitProgress = ref(false);
	const { resetPassword } = useAuth();

	async function submit() {
		await validation.value.$validate();
		if (validation.value.$error) return;

		validation.value.$reset();
		error.value = "";
		submitProgress.value = true;
		try {
			await resetPassword(props.email, formData.otp, formData.password);
			emit("done");
		} catch (e) {
			error.value = (e as Error).message;
		}
		submitProgress.value = false;
	}
</script>

<template>
	<TextInput
		:model-value="email"
		:placeholder="'Email'"
		type="email"
		autocomplete="email"
	>
		<template #prepend>
			<UserIcon />
		</template>
	</TextInput>
	<TextInput
		v-model="formData.otp"
		:placeholder="'Verification code'"
		autocomplete="one-time-code"
		:error="validation.otp.$errors?.[0]?.$message"
		@blur="validation.otp.$touch"
	/>
	<TextInput
		v-model="formData.password"
		:placeholder="'New password'"
		type="password"
		autocomplete="current-password"
		:error="validation.password.$errors?.[0]?.$message || error"
		@blur="validation.password.$touch"
	>
		<template #prepend>
			<PasswordIcon />
		</template>
	</TextInput>
	<button
		class="button button-primary"
		@click="submit"
		:disabled="submitProgress"
	>
		Submit
	</button>
	<button
		class="button button-secondary"
		@click="emit('done')"
	>
		Back
	</button>
</template>

<style scoped>
	.error {
		color: var(--theme-error);
		text-align: end;
		margin-bottom: 1rem;
	}
</style>
