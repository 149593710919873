<script setup lang="ts">
	import useVuelidate from "@vuelidate/core";
	import {
		email,
		maxLength,
		minLength,
		numeric,
		required,
	} from "@vuelidate/validators";
	import { computed, reactive, ref } from "vue";
	import { useAuth } from "../../composables/api/auth";
	import { TextInput } from "@jca/libs/ui";

	const emit = defineEmits<{
		(e: "done"): void;
	}>();

	const { forgotPassword } = useAuth();
	let error = ref("");

	let submitProgress = ref(false);

	const formData = reactive({
		email: "",
		otp: "",
		password: "",
	});

	const rules = computed(() => {
		if (submitProgress.value) {
			return {
				password: { required },
				otp: {
					required,
					numeric,
					minLength: minLength(6),
					maxLength: maxLength(6),
				},
				email: { required, email },
			};
		} else {
			return { email: { required, email }, password: {}, otp: {} };
		}
	});

	const validation = useVuelidate(rules, formData);

	const submit = ref<(code: string, password: string) => Promise<void>>();

	async function handleSubmit() {
		try {
			await validation.value.$validate();
			if (validation.value.$error) return;

			validation.value.$reset();
			error.value = "";
			submitProgress.value = true;
			if (!submit.value) {
				submit.value = await forgotPassword(formData.email);
				error.value = "";
				validation.value.$reset();
			} else {
				await submit.value(formData.otp, formData.password);
				emit("done");
			}
			submitProgress.value = false;
		} catch (e) {
			error.value = (e as Error).message;
		}
	}

	function back() {
		if (submit.value) {
			submit.value = undefined;
			error.value = "";
			validation.value.$reset();
		} else {
			emit("done");
		}
	}
</script>

<template>
	<TextInput
		v-model="formData.email"
		:disabled="!!submit"
		:placeholder="'Email'"
		type="email"
		autocomplete="email"
		:error="validation.email.$errors?.[0]?.$message"
		@blur="validation.email.$touch"
	>
		<template #prepend>
			<UserIcon />
		</template>
	</TextInput>
	<TextInput
		v-if="submit"
		v-model="formData.otp"
		:placeholder="'Verification Code'"
		autocomplete="one-time-code"
		:error="validation.otp.$errors?.[0]?.$message"
		@blur="validation.otp.$touch"
	/>
	<TextInput
		v-if="submit"
		v-model="formData.password"
		:placeholder="'New password'"
		:type="'password'"
		autocomplete="current-password"
		icon="password"
		:error="validation.password.$errors?.[0]?.$message || error"
		@blur="validation.password.$touch"
	>
		<template #prepend>
			<PasswordIcon />
		</template>
	</TextInput>
	<button
		class="button button-primary"
		@click="handleSubmit"
		:disabled="submitProgress"
	>
		Submit
	</button>
	<button
		class="button button-secondary"
		@click="back"
		:disabled="submitProgress"
	>
		Back
	</button>
</template>

<style scoped>
	.error {
		color: var(--theme-error);
		text-align: end;
		margin-bottom: 1rem;
	}
</style>
