<template>
	<i class="w-12 h-12 flex justify-center items-center">
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4.81139 18.1068C5.95018 17.3855 7.09889 16.8446 8.25751 16.484C9.41615 16.1234 10.6593 15.9431 11.987 15.9431C13.3148 15.9431 14.5718 16.1329 15.758 16.5125C16.9442 16.8921 18.0973 17.4235 19.2171 18.1068C19.9953 17.0819 20.5504 16.0906 20.8826 15.1329C21.2147 14.1752 21.3808 13.1357 21.3808 12.0142C21.3808 9.35237 20.4809 7.12107 18.6811 5.32034C16.8813 3.51959 14.6512 2.61922 11.9907 2.61922C9.33024 2.61922 7.09846 3.51959 5.29537 5.32034C3.49229 7.12107 2.59075 9.35237 2.59075 12.0142C2.59075 13.153 2.75708 14.1942 3.08974 15.1379C3.4224 16.0815 3.99628 17.0711 4.81139 18.1068ZM11.9816 12.9822C10.8616 12.9822 9.91798 12.5965 9.15078 11.825C8.38359 11.0536 8 10.1141 8 9.00652C8 7.89897 8.38498 6.95611 9.15493 6.17794C9.92489 5.39976 10.8699 5.01068 11.9899 5.01068C13.1099 5.01068 14.0536 5.40115 14.8208 6.18209C15.5879 6.96302 15.9715 7.90726 15.9715 9.01483C15.9715 10.1224 15.5866 11.0605 14.8166 11.8292C14.0466 12.5979 13.1016 12.9822 11.9816 12.9822ZM11.9697 24C10.3377 24 8.79182 23.6891 7.3321 23.0674C5.87238 22.4457 4.59294 21.5816 3.49378 20.4752C2.39464 19.3687 1.53756 18.0973 0.922534 16.661C0.307511 15.2246 0 13.672 0 12.0031C0 10.3532 0.31516 8.80263 0.94548 7.35132C1.57582 5.89999 2.43907 4.6276 3.53523 3.53415C4.63139 2.4407 5.89864 1.57832 7.33697 0.946989C8.7753 0.315663 10.3296 0 12 0C13.6514 0 15.2007 0.32019 16.648 0.960569C18.0952 1.60095 19.3625 2.46529 20.4498 3.55359C21.537 4.6419 22.4006 5.91113 23.0403 7.36128C23.6801 8.81145 24 10.3599 24 12.0067C24 13.6754 23.6843 15.2282 23.053 16.6651C22.4217 18.1021 21.5586 19.3686 20.4639 20.4648C19.3691 21.5609 18.0941 22.4242 16.6389 23.0545C15.1836 23.6848 13.6272 24 11.9697 24Z"
				fill="#0F384D"
			/>
		</svg>
	</i>
</template>
