import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { computed, type Ref } from "vue";
import type { Project, Api } from "../api-models";
import { GroupType, type Group } from "./user";
import {useAuth} from "@/composables/api/auth";

const {user} = useAuth();

export function useProjectList(api: Api<unknown>) {
	return useQuery({
		queryKey: ["projects"],
		queryFn: async () => {
			const response = await api.umc.projectsList();
			if (response.status !== 200) throw new Error(await response.text());
			return response.data;
		},
	});
}

export  function useProjectListByUser(api: Api<unknown>) {
	const email =  user.value?.email;
	return useQuery({
		queryKey: ["projects"],
		queryFn: async () => {
			const response = await api.umc.getProjectByUser({
				userName: email??'',
			});
			if (response.status !== 200) throw new Error(await response.text());
			return response.data;
		},
	});
}

export function useGetProject(
	api: Api<unknown>,
	projectId: Ref<string | undefined>,
) {
	return useQuery<Project>({
		queryKey: ["projects", projectId],
		queryFn: async () => {
			const response = await api.umc.getProjectById({
				projectId: projectId.value!,
			});
			if (response.status !== 200) throw new Error(await response.text());
			return response.data;
		},
		enabled: computed(() => projectId.value !== undefined),
	});
}



export const useAddProjectMutation = (api: Api<unknown>) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async ({
			name,
			description,
			ownerEmail,
		}: {
			name: string;
			description: string;
			ownerEmail: string;
		}) => {
			const newProject = {
				ProjectId: crypto.randomUUID(),
				Name: name,
				Description: description,
			} as Project;

			const request = {
				Project: newProject,
				ProjectOwner: {
					userName: ownerEmail,
					groupName: GroupType.ProjectOwner as Group,
				},
			};

			try {
				await api.umc.projectsCreate(request);
			} catch (e: any) {
				throw e.error as string;
			}
		},
		onSuccess: () =>
			queryClient.invalidateQueries({ queryKey: ["projects"] }),
	});
};

export const useDeleteProjectMutation = (api: Api<unknown>) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async (projectId: string) => {
			try {
				await api.umc.projectsDelete({
					projectId: projectId,
				});
			} catch (e: any) {
				throw e.error as string;
			}
		},
		onSuccess: () =>
			queryClient.invalidateQueries({ queryKey: ["projects"] }),
	});
};