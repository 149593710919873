<script setup lang="ts">
	import awsExports from "@/aws-exports";
	import CertificateIcon from "@/icons/CertificateIcon.vue";
	import { PackageType } from "@/types/package-type";
	import { fetchAuthSession } from "aws-amplify/auth";
	import { S3Client } from "@aws-sdk/client-s3";
	import type { MachineCredentialCertificateDto } from "@/composables/machie-certificate-api";
	import {
		DialogLayout,
		downloadFile,
		getObjectBlob,
		getObjectHead,
	} from "@jca/libs/ui";

	import { ref } from "vue";

	const credential = ref<MachineCredentialCertificateDto>();
	const machineCredentialBucket = "jcaportal-machinecredential";
	const dialogRef = ref<HTMLDialogElement>();
	const displayDownloadError = ref(false);
	const downloadMessageError = ref("");
	const date = ref(new Date());

	async function download(
		credential: MachineCredentialCertificateDto,
		packageType: PackageType,
	) {
		try {
			const authSession = await fetchAuthSession();
			const credentials = authSession.credentials;

			const cognitoUserCreds = credentials as {
				accessKeyId: string | undefined;
				secretAccessKey: string | undefined;
				sessionToken: string | undefined;
			};

			if (
				cognitoUserCreds.accessKeyId === undefined ||
				cognitoUserCreds.secretAccessKey === undefined ||
				cognitoUserCreds.sessionToken === undefined
			) {
				displayDownloadError.value = true;
				downloadMessageError.value =
					"Please, login to download the certificate.";
				return false;
			}

			const s3Client = new S3Client({
				region: awsExports.aws_project_region,
				credentials: {
					accessKeyId: cognitoUserCreds.accessKeyId,
					secretAccessKey: cognitoUserCreds.secretAccessKey,
					sessionToken: cognitoUserCreds.sessionToken,
				},
			});

			const s3key =
				packageType === PackageType.Debian
					? credential.DebianFilePath
					: credential.ZipFilePath;
			const fileExtension = packageType === PackageType.Debian ? "deb" : "zip";

			const head = await getObjectHead(
				s3Client,
				machineCredentialBucket,
				s3key!,
			);
			if ((head.ContentLength ?? 0) > 500000000) {
				//500 MB
				displayDownloadError.value = true;
				downloadMessageError.value = "Package is too big.";
				return false;
			}

			const file = await getObjectBlob(
				s3Client,
				machineCredentialBucket,
				s3key!,
			);
			const filename = `${credential.MachineName ?? credential.MachineId}`;
			const formattedDate = formatDate(new Date(date.value));

			downloadFile(`${filename}-${formattedDate}.${fileExtension}`, file);
		} catch (error) {
			console.error(error);
			displayDownloadError.value = true;
			downloadMessageError.value =
				"Error while downloading machine credential package.";
		}
	}

	const formatDate = (date: Date): string => {
		const year = date.getFullYear();
		const month = `0${date.getMonth() + 1}`.slice(-2);
		const day = `0${date.getDate()}`.slice(-2);
		return `${year}-${month}-${day}`;
	};

	function openDialog(_credential: MachineCredentialCertificateDto) {
		credential.value = _credential;
		dialogRef.value?.showModal();
	}

	function closeDialog() {
		dialogRef.value?.close();
	}

	defineExpose({
		openDialog,
	});
</script>

<template>
	<dialog ref="dialogRef">
		<DialogLayout>
			<template #title>
				<span>Machine Credential</span>
			</template>

			<p class="body-2">Machine Name: {{ credential?.MachineName }}</p>
			<p class="body-2 mt-1">Machine Id: {{ credential?.MachineId }}</p>
			<hr class="mt-3" />

			<span class="body-2-bold">Certificates</span>

			<div class="mt-3 flex items-center justify-start">
				<CertificateIcon class="text-green" />
				<span class="value-label"
					>The Debian and Zip files contain:</span
				>
			</div>
			<div class="mt-3 flex items-center justify-center">
				<ul>
					<li class="text-sm">remoteaccess.yaml</li>
					<li class="text-sm">pem certificate</li>
					<li class="text-sm">private key</li>
					<li class="text-sm">public key</li>
					<li class="text-sm">AmazonRootCA1.pem</li>
					<li class="text-sm">AmazonRootCA3.pem</li>
				</ul>
			</div>

			<div class="mt-3 flex items-center justify-center">
				<i class="material-icons text-yellow-500">warning</i>
				<span class="body-2-bold text-yellow-500"
					>These files can be downloaded at any time.</span
				>
			</div>

			<div class="mt-4 flex items-center justify-center">
				<button
					class="button bg-netural-green"
					@click="download(credential!, PackageType.Debian)"
				>
					Download Debian
				</button>
				<button
					class="button bg-netural-green"
					@click="download(credential!, PackageType.Zip)"
				>
					Download ZIP
				</button>
			</div>

			<hr class="mt-3" />

			<template #actions>
				<span
					v-if="displayDownloadError"
					class="body-2-bold text-error"
					>{{ downloadMessageError }}</span
				>
				<button
					class="mb-2 mr-2 flex items-center rounded bg-light-orange px-4 py-2 font-bold text-dark-orange md:mb-0"
					@click="closeDialog"
				>
					Close
				</button>
			</template>
		</DialogLayout>
	</dialog>
</template>
