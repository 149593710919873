<template>
	<li
		:class="[
			class_items,
			{ 'bg-dark-green': !isActive(), 'bg-white': isActive() },
		]"
	>
		<router-link
			:to="{ path: url }"
			class="relative"
		>
			<span
				:class="[
					'font-semibold',
					{ 'text-jca-grey': !isActive(), 'text-bright-orange': isActive() },
				]"
				>{{ title }}</span
			>
			<div
				v-if="notification"
				class="absolute -right-12 -top-2 flex h-8 w-8 items-center justify-center rounded-full bg-pumpkin-secondary text-dark-green"
			>
				<span class="text-md font-bold">{{ notification_num }}</span>
			</div>
		</router-link>
	</li>
</template>

<script setup lang="ts">
	import { defineProps } from "vue";
	import { useRoute, RouterLink } from "vue-router";

	const props = defineProps({
		title: String,
		url: String,
		class_items: String,
		notification: Boolean,
		notification_num: String,
	});

	const isActive = () => {
		// Get the current route's path from Vue Router
		const currentRoute = useRoute();
		return props.url === currentRoute.path;
	};
</script>

<style scoped>
	li {
		@apply list-none;
	}

	.li_main_top {
		@apply border-t-2;
	}

	.li_main_bottom {
		@apply border-b-2;
	}

	.li_main_top,
	.li_main_bottom,
	.li_main {
		@apply min-w-max gap-20 border-medium-green px-2 py-6;
	}

	.li_main_top a,
	.li_main_bottom a .li_main a {
		@apply text-white;
	}

	.li_main_top a span,
	.li_main_bottom a span,
	.li_main a span {
		@apply text-xl;
	}

	a {
		@apply no-underline;
	}
</style>
