<script setup lang="ts">
	import ClockSpinnerIcon from "@/icons/ClockSpinnerIcon.vue";
	import { DialogLayout } from "@jca/libs/ui";
	import { computed, reactive, ref, watchEffect } from "vue";
	import Dropdown from "@/components/Dropdown.vue";
	import { Option } from "@/components/Dropdown.vue";
	import { Project } from "@/composables/api-models";
	import { useHttp, useProjectList } from "@/composables/umc-api";
	import { emptyGUID } from "@jca/libs/api";
	import { z } from "zod";
	import { useValidation } from "@jca/libs/forms";
	import { type Machine } from "@/composables/machie-certificate-api";
	import { Checkbox, TextInput } from "@jca/libs/ui";

	const props = defineProps<{
		loading: boolean;
		error?: string;
		machine: Machine;
	}>();

	const emit = defineEmits<{
		close: [
			{
				newProjectId: string;
				organizationId: string;
				organizationName: string;
			}?,
		];
	}>();

	//set default checkbox value
	const isOrganizationIdChecked = ref(
		props.machine.OrganizationId != "" && props.machine.OrganizationId != null,
	);

	const schema = {
		newProjectId: z.string().min(1, { message: "Project is required" }),
		organizationId: z
			.string()
			.min(1, { message: "Organization ID is required" }),
		organizationName: z
			.string()
			.min(1, { message: "Organization Name is required" }),
	};
	const machineSchema = computed(() => {
		if (!isOrganizationIdChecked.value) {
			schema.organizationId = z.string();
			schema.organizationName = z.string();
		}
		return z.object(schema);
	});

	const result = reactive({
		newProjectId: "", // set the default value to current project
		organizationId: props.machine.OrganizationId ?? "",
		organizationName: props.machine.OrganizationName ?? "",
	});

	const validation = useValidation(result, machineSchema.value);

	async function save() {
		if (validation.validate()) {
			emit("close", {
				newProjectId: result.newProjectId,
				organizationId: result.organizationId,
				organizationName: result.organizationName,
			});
		}
	}

	const http = useHttp();
	const { isLoading: isProjectLoading, data: projects } = useProjectList(http);
	const isLoading = computed(() => {
		return isProjectLoading.value;
	});
	// Track the state of the dropdown and search box
	const isDropdownOpen = ref(false);
	const isTyping = ref(false);
	// Track the state of the dropdown (whether it is open or not)
	// Function to toggle dropdown open/close state
	const toggleDropdown = () => {
		isDropdownOpen.value = !isDropdownOpen.value;
	};

	// Function to handle typing in the search box
	const handleSearchTyping = () => {
		isTyping.value = true;
	};

	// we don't want to show the empty project for admin
	const filterProjects = (projects: Project[]) => {
		return projects.filter(project => project.ProjectId !== emptyGUID);
	};

	const handleSelection = (selectedProject: Option) => {
		result.newProjectId = selectedProject.ProjectId;
	};
	// Function to convert projects to the Option[] type
	function convertToOption(project: Project): Option {
		var option = {
			id: project.ProjectId ?? "", // Assign a default value if project.ProjectId is undefined
			label: project.Name ?? "",
			...project,
		};
		if (project.ProjectId == props.machine.ProjectId) {
			defaultSelected.value = option;
			//set result.newProjectId to the default selected project
			result.newProjectId = option.id;
		}
		return option;
	}
	const options = ref<Option[]>([]); // Array of options to be populated after fetching
	const defaultSelected = ref<Option>(); // Default selected option

	watchEffect(() => {
		if (projects.value) {
			options.value = filterProjects(projects.value).map(convertToOption);
		}
		//show the organization name and id
		if (isOrganizationIdChecked.value) {
			schema.organizationId = z
				.string()
				.regex(
					/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/,
					{ message: "Invalid GUID format" },
				);
			schema.organizationName = z
				.string()
				.min(3, "Must be at least 3 characters long")
				.max(50, "Must be at most 50 characters long")
				.regex(
					/^(?!.*\/\/)(?!.*__)[a-zA-Z_][a-zA-Z0-9_/]{1,48}[a-zA-Z0-9_]$/,
					"It can only contain (a-z, A-Z),(0-9) and _/",
				);
		} else {
			result.organizationId = "";
			result.organizationName = "";
			schema.organizationId = z.string();
			schema.organizationName = z.string();
		}
	});
</script>

<template>
	<DialogLayout class="w-[20rem] bg-netural-orange">
		<template #title>
			<span class="font-bold text-dark-green">Edit Machine</span>
		</template>

		<div :class="['editMachineDropDown mx-auto w-full max-w-sm']">
			<span class="value-label mb-1 pl-4 text-dark-green"> Project Name </span>
			<Dropdown
				:options="options"
				placeholder="Select a project"
				valueField="ProjectId"
				displayField="Name"
				@click="toggleDropdown"
				@search="handleSearchTyping"
				@update:modelValue="handleSelection"
				:defaultSelected="defaultSelected"
			/>
			<div
				v-if="validation.errors.newProjectId"
				class="error-text absolute top-[4.5rem] ml-4"
			>
				{{ validation.errors.newProjectId }}
			</div>
		</div>
		<div class="editMachineCheckbox mx-auto w-full max-w-sm">
			<Checkbox
				v-model="isOrganizationIdChecked"
				label="Is this associated with an organization?"
				class="mt-1"
			/>
		</div>
		<TextInput
			v-model="result.organizationId"
			label="Organization ID"
			placeholder="Organization ID"
			class="w-full"
			v-if="isOrganizationIdChecked"
			:error="validation.errors.organizationId"
		/>

		<TextInput
			v-model="result.organizationName"
			label="Organization Name"
			placeholder="Organization Name"
			class="w-full"
			v-if="isOrganizationIdChecked"
			:error="validation.errors.organizationName"
		/>

		<template #actions>
			<button
				class="button bg-light-orange"
				:disabled="props.loading"
				@click="emit('close')"
			>
				Cancel
			</button>
			<button
				class="button bg-bright-orange"
				:disabled="props.loading"
				@click="save"
			>
				<ClockSpinnerIcon v-if="loading" />
				<span>Save</span>
			</button>
		</template>
	</DialogLayout>
</template>
<style scoped>
	.editMachineDropDown {
		@apply relative h-[6rem] w-full;
	}

	.checkbox {
		@apply px-3;
	}
</style>
