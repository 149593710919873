<script setup lang="ts">
	import AddUser from "/src/components/UMC/AddUser.vue";
	import EditUser from "/src/components/UMC/EditUser.vue";
	import ListUser from "@/components/UMC/ListUser.vue";
	import {
		GroupType,
		useAddUserMutation,
		useDeleteUserMutation,
		useGetProject,
		useHttp,
		useReinviteUserMutation,
		UserLevel,
		useUpdateEnableUserMutation,
		useUpdateUserMutation,
		useUserList,
		useUserListByGroup,
		type FlatUser,
		type Group,
	} from "@/composables/umc-api";
	import { setCustomerIdWithProjectId } from "@/composables/api/auth";
	import { AsyncDialog, ConfirmDialog } from "@jca/libs/confirm";
	import { computed, ref, watch, watchEffect } from "vue";
	import PageHeader from "@/components/Navigation/PageHeader.vue";
	import { emptyGUID } from "@jca/libs/api";
	import { Option } from "@/components/Dropdown.vue";


	const props = defineProps<{
		projectId: string;
		userType: string;
	}>();

	const page = ref(1);
	const pageSize = ref(10);
	const maxPageSize = ref(60); //max page size for the user list

	// update project id in header

	const http = useHttp();
	//Dialogs
	const confirmDialog = ref<InstanceType<typeof ConfirmDialog> | null>(null);
	const editDialog = ref<InstanceType<typeof AsyncDialog> | null>(null);
	const addDialog = ref<InstanceType<typeof AsyncDialog> | null>(null);

	//Order of the functions does matter since we are setting the customerId for header
	//get the admin user group
	//TODO: We need the pagination function for add subscriber dropdown later (low priority). 
	setCustomerIdWithProjectId(emptyGUID);
	const adminGroup = ref<Group>(GroupType.PortalAdmin);
	const admins = useUserListByGroup(
		http,
		adminGroup,
		ref(emptyGUID),
		page,
		maxPageSize,
	);

	setCustomerIdWithProjectId(props.projectId);
	const {
		isLoading,
		isFetching,
		data: userList,
		lastPage,
	} = useUserList(http, page, pageSize);

	const project = useGetProject(
		http,
		computed(() => props.projectId),
	);

	const userCreate = useAddUserMutation(http);
	const userUpdate = useUpdateUserMutation(http);
	const userUpdateEnabled = useUpdateEnableUserMutation(http);
	const userDelete = useDeleteUserMutation(http);
	const userResend = useReinviteUserMutation(http);

	function addUser() {
		userCreate.reset();
		addDialog.value?.open<
			void,
			{ email: string; selectedGroup: Group; projectId: string }
		>({
			callback: async result => {
				await userCreate.mutateAsync(result);
				return true;
			},
		});
	}

	function editUser(item: FlatUser) {
		userUpdateEnabled.reset();
		editDialog.value?.open<FlatUser, FlatUser>({
			dialogData: { ...item },
			callback: async result => {
				userUpdate.reset();
				if (result.Enabled !== item.Enabled) {
					await userUpdateEnabled.mutateAsync({
						email: item.Email,
						enabled: result.Enabled,
						projectId: props.projectId,
					});
				}
				return true;
			},
		});
	}

	async function deleteUser(item: FlatUser) {
		//check user type
		var msg = "";
		var okText = "";
		if (props.userType == UserLevel.Subscriber) {
			msg = `Are you sure you want to unsubscribe ${item.Email} from the project? `;
			okText = "Unsubscribe";
		} else {
			msg = `Are you sure you want to delete ${item.Email}?`;
			okText = "Delete";
		}
		const result = await confirmDialog.value?.open({
			title: `Confirm ${okText}`,
			text: msg,
			okText: okText,
			waitOnConfirm: true,
		});
		console.log("result", item.Email);
		if (!result) return;

		await userDelete.mutateAsync({
			email: item.Email,
			projectId: props.projectId,
		});
		result();
	}

	async function resendUser(item: FlatUser) {
		const result = await confirmDialog.value?.open({
			title: "Confirm resend",
			text: "Are you sure you want to resend this email",
			okText: "Resend",
			waitOnConfirm: true,
		});
		if (!result) return;

		await userResend.mutateAsync({
			email: item.Email,
			projectId: props.projectId,
		});
		result();
	}

	// Function to convert projects to the Option[] type
	function convertToOption(user: FlatUser): Option {
		return {
			id: user.Username ?? "", // Assign a default value if project.ProjectId is undefined
			label: user.Username ?? "",
			...project,
		};
	}

	const userOptions = ref<Option[]>([]);
	watchEffect(() => {
		if (props.userType == UserLevel.Subscriber) {
			if (admins.data.value) {
				userOptions.value = admins.data.value.map(convertToOption);
			}
		}
	});

	watch(() => props.userType, () => {
		//we need to maually reload the page, since the users and sbscribers are different
		window.location.reload();
	});
</script>

<template>
	<PageHeader
		:title="project.data.value?.Name + ': Subscriptions'"
		:back="true"
		v-if="props.userType == UserLevel.Subscriber"
	/>

	<PageHeader
		:title="props.userType"
		:back="true"
		v-else
	>
	</PageHeader>

	<div></div>
	<div class="box-border flex flex-col gap-4 pl-5">
		<ListUser
			v-model:page="page"
			v-model:page-size="pageSize"
			:user-type="props.userType"
			:users="userList ?? []"
			:loading="isLoading"
			:fetching="isFetching"
			:last-page="lastPage"
			@add="addUser"
			@edit="editUser"
			@delete="deleteUser"
			@resend="resendUser"
		/>

		<ConfirmDialog ref="confirmDialog" />
		<AsyncDialog
			ref="editDialog"
			v-slot="slotProps"
		>
			<EditUser
				:user="slotProps.data"
				:loading="slotProps.loading"
				:error="userUpdate.error.value ?? userUpdateEnabled.error.value"
				@close="slotProps.close($event)"
			/>
		</AsyncDialog>
		<AsyncDialog
			ref="addDialog"
			v-slot="slotProps"
		>
			<AddUser
				:user-type="props.userType"
				:user-options="userOptions"
				:users="userList ?? []"
				:loading="slotProps.loading"
				:error="userCreate.error.value"
				@close="slotProps.close($event)"
			/>
		</AsyncDialog>
	</div>
</template>
