import {
    resetPassword as awsResetPassword,
    signIn as awsSignIn,
    signOut as awsSignOut,
    confirmResetPassword,
    confirmSignIn,
    fetchAuthSession,
} from "aws-amplify/auth";
import { onMounted, ref } from "vue";
import { emptyGUID } from "@jca/libs/api";


export type PortalUser = {
	email: string;
	username: string;
	projectId: string;
	emailVerified: boolean;
	groups: string[];
};

export async function getUserFromIdToken(): Promise<PortalUser> {
	const session = await fetchAuthSession();
	const idToken = session.tokens?.idToken?.payload;
	if (!idToken) throw new Error("No id token found");

	return {
		email: idToken.email as string,
		username: idToken["cognito:username"] as string,
		projectId: idToken["custom:ProjectId"] as string,
		emailVerified: idToken.email_verified as boolean,
		groups: idToken["cognito:groups"] as string[],
	};
}

export async function getToken(): Promise<string> {
	const { tokens } = await fetchAuthSession();
	return tokens?.idToken?.toString() ?? "";
}

export function useAuth() {
	const user = ref<PortalUser | null>(null);

	async function signIn(
		username: string,
		password: string,
	): Promise<{
		user?: PortalUser;
		completeNewPassword?: (
			password: string,
			apiUrl: string,
		) => Promise<void>;
	}> {
		try {
			const result = await awsSignIn({ username, password });
			if (
				result.nextStep.signInStep ===
				"CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
			) {
				return {
					completeNewPassword: async (password, apiUrl) => {
						await confirmSignIn({
							challengeResponse: password,
						});
						const authSession = await fetchAuthSession();
						const token = authSession.tokens?.idToken?.toString();
						if (!token) {
							throw new Error("No id token found");
						}
						
						user.value = await getUserFromIdToken();
						//send token to api to update user
						//we can't use the http client here because it's not initialized yet
						const response = await fetch(`${apiUrl}/umc/user/activate?username=${username}`, {
							method: "PUT",
							headers: {
								"Content-Type": "application/json",
								Authorization: `Bearer ${token}`,
							}
						});
					},
				};
			} else {
				user.value = await getUserFromIdToken();
				return { user: user.value };
			}
		} catch (error) {
			console.error(`Sign in error: ${error}`);
			throw error;
		}
	}

	async function signOut() {
		try {
			await awsSignOut();
			user.value = null;
		} catch (error) {
			console.log("error signing out: ", error);
		}
	}

	async function forgotPassword(username: string) {
		try {
			await awsResetPassword({ username });
			return async (confirmationCode: string, newPassword: string) => {
				try {
					await confirmResetPassword({
						username,
						confirmationCode,
						newPassword,
					});
				} catch (error) {
					console.error(error);
					throw error;
				}
			};
		} catch (error) {
			console.error(error);
			throw error;
		}
	}

	async function resetPassword(
		username: string,
		confirmationCode: string,
		newPassword: string,
	) {
		try {
			await confirmResetPassword({
				username,
				confirmationCode,
				newPassword,
			});
		} catch (error) {
			console.error(error);
			throw error;
		}
	}

	onMounted(async () => {
		try {
			user.value = await getUserFromIdToken();
		} catch (error) {
			console.log("Not logged in");
		}
	});

	return { signIn, signOut, user, forgotPassword, resetPassword };
}
const customerId = ref(emptyGUID);

export function useCustomerId() {
	return customerId.value;
}

export function useCustomerIdRef() {
	return customerId;
}

export async function setCustomerIdWithProjectId(projectId: string) {
	customerId.value = projectId;
}

