<script setup lang="ts">
	import ClockSpinnerIcon from "@/icons/ClockSpinnerIcon.vue";
	import { useValidation } from "@jca/libs/forms";
	import { DialogLayout, TextInput } from "@jca/libs/ui";
	import { reactive, ref } from "vue";
	import { z } from "zod";
	import Dropdown from "@/components/Dropdown.vue";
	import type { Option } from "@/components/Dropdown.vue";

	const props = defineProps<{
		loading: boolean;
		error?: string | null;
		userOptions: Option[];
	}>();

	type NewProject = {
		name: string;
		description: string;
		ownerEmail: string;
	};

	const emit = defineEmits<{
		close: [newProject?: NewProject];
	}>();

	const projectSchema = z.object({
		name: z.string().min(1, { message: "Name is required" }),
		description: z.string().min(1, { message: "Description is required" }),
		ownerEmail: z.string().email("Invalid email address"),
	});

	const project = reactive({
		name: "",
		description: "",
		ownerEmail: "",
	} as NewProject);

	const validation = useValidation(project, projectSchema);

	async function saveProject() {
		if (validation.validate()) emit("close", project);
	}

	// Track the state of the dropdown and search box
	const isDropdownOpen = ref(false);
	const isTyping = ref(false);
	// Track the state of the dropdown (whether it is open or not)
	// Function to toggle dropdown open/close state
	const toggleDropdown = () => {
		isDropdownOpen.value = !isDropdownOpen.value;
	};

	// Function to handle typing in the search box
	const handleSearchTyping = () => {
		isTyping.value = true;
	};

	const handleSelection = (selectedUser: Option) => {
		project.ownerEmail = selectedUser.label;
		if (selectedUser.label == "Select a User") {
			alert("Please select a user");
		}
	};
</script>

<template>
	<DialogLayout class="w-[20rem] bg-netural-orange">
		<template #title>
			<span class="font-bold text-dark-green">Add Project</span>
		</template>

		<TextInput
			v-model="project.name"
			:label="'Project Name'"
			placeholder=" "
			:error="validation.errors.name"
			@blur="validation.touch.name"
		/>
		<TextInput
			v-model="project.description"
			:label="'Project Description'"
			placeholder=" "
			:error="validation.errors.description"
			@blur="validation.touch.description"
			class="mb-2 text-dark-green"
		/>
		<label
			class="relative mb-2 flex flex-col items-stretch pb-4 text-dark-green"
			><span class="mb-1 pl-4"
				>Subscribed User</span
			>
			
			<Dropdown
				:options="props.userOptions"
				placeholder=""
				valueField="id"
				displayField="id"
				@click="toggleDropdown"
				@search="handleSearchTyping"
				@update:modelValue="handleSelection"
			/>
			<div
				v-if="validation.errors.ownerEmail"
				class="error-text ml-4 absolute top-[4.5rem]"
			>
				Please select a user
			</div>
		</label>

		<div
			v-if="error"
			class="text-red"
			style="bottom: -1rem"
		>
			{{ error }}
		</div>
		<template #actions>
			<button
				class="button bg-light-orange"
				:disabled="props.loading"
				@click="emit('close')"
			>
				Cancel
			</button>
			<button
				class="button bg-bright-orange"
				:class="{ 'button-error': !validation.valid.value }"
				:disabled="props.loading"
				@click="saveProject"
			>
				<ClockSpinnerIcon v-if="loading" />
				<span>Save</span>
			</button>
		</template>
	</DialogLayout>
</template>
