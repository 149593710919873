import { fetchAuthSession } from "aws-amplify/auth";
import { createRouter, createWebHistory } from "vue-router";
import { useAuth } from "@/composables/auth";
import Dashboard from "@/pages/Dashboard.vue";
import Page from "@/components/Page.vue";
import ActiveConnections from "@/pages/RemoteAccess/ActiveConnections.vue";
import ConnectionHistory from "@/pages/RemoteAccess/ConnectionHistory.vue";
import Machines from "@/pages/RemoteAccess/Machines.vue";
import { initMachineCertificateClient } from "@/composables/machie-certificate-api";
import { initAuditTrailsHttp } from "../composables/audit-trails-api";
import awsmobile from "../aws-exports";
import { initClient } from "@/composables/umc-api";
import ProjectsView from "@/pages/UMC/ProjectsView.vue";
import UsersView from "@/pages/UMC/UsersView.vue";
import AuthView from "@/pages/AuthView.vue";
import MyProjectsView from "/src/pages/UMC/SubsriptonsView.vue";

const { cachedAccessToken, cachedIdToken, loadCurrentUser, currentUser } =
	useAuth();

const router = createRouter({
	history: createWebHistory(),
	routes: [
		{
			path: "/",
			component: Page,
			name: "home",
			meta: { requiresAuth: true },
			redirect: to => {
				return "my_subscriptions";
			},
			children: [
				{
					path: "/dashboard",
					name: "dashboard",
					component: Dashboard,
					meta: { requiresAuth: true },
				},
				{
					path: "/remote_access_active",
					name: "remote_access_active",
					component: ActiveConnections,
					meta: { requiresAuth: true },
				},
				{
					path: "history/:machineId/:machineName",
					name: "history",
					component: ConnectionHistory,
					meta: { requiresAuth: true },
					props: true,
				},
				{
					path: "/machines/:projectId/machines",
					name: "machines",
					component: Machines,
					meta: { requiresAuth: true },
					props: true,
				},
				{
					path: "/projects",
					name: "projects",
					component: ProjectsView,
					meta: { requiresAuth: true },
				},
				{
					path: "/my_subscriptions",
					name: "my_subscriptions",
					component: MyProjectsView,
					meta: { requiresAuth: true },
				},
				{
					path: "/:userType/:projectId/",
					name: "users",
					component: UsersView,
					meta: { requiresAuth: true },
					props: true,
				},
			
			],
		},
		{
			path: "/login",
			name: "login",
			component: AuthView,
		},
	],
});
router.beforeEach(async to => {
	try {
		const session = await fetchAuthSession();
		cachedAccessToken.value = session.tokens?.accessToken.toString() ?? null;
		cachedIdToken.value = session.tokens?.idToken?.toString() ?? null;

		if (!cachedAccessToken.value || !cachedIdToken.value) {
			throw new Error("Invalid auth tokens");
		}
		//get the curren application's endpoint
		initMachineCertificateClient(awsmobile.aws_cloud_logic_custom[0].endpoint);
		initAuditTrailsHttp(awsmobile.aws_cloud_logic_custom[0].endpoint);
		initClient(false,awsmobile.aws_cloud_logic_custom[0].endpoint);
	} catch (e: unknown) {
		if (to.name !== "login") {
			return {
				name: "login",
				query: { redirect: to.name?.toString() },
			};
		}
	}
});

router.onError((error, to) => {
	if (
		error.message.includes("Failed to fetch dynamically imported module") ||
		error.message.includes("Importing a module script failed")
	) {
		window.location = to.fullPath as string & Location;
	}
});

export default router;
