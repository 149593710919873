<script setup lang="ts">
	import PageHeader from "@/components/Navigation/PageHeader.vue";
	import { computed, ref } from "vue";
	import { useRouter, useRoute } from "vue-router";
	import { defineProps } from "vue";
	import {
		useAuditTrailsHttp,
		useAuditTrailsEndActiveConnection,
	} from "@/composables/audit-trails-api";
	import {
		AuditTrailsPageType,
		useAuditTrailsByMachine,
	} from "@/composables/audit-trails-api/audit-trails";
	import { AuditTrailsType, DeleteTaskResponse } from "@/composables/api-models";
	import ListConnection from "@/components/RemoteAccess/ListConnection.vue";
	import { ConfirmDialog } from "@jca/libs/confirm";
	import EndConnection from "@/components/AuditTrails/EndConnection.vue";
	import Disclaimer from "@/components/AuditTrails/Disclaimer.vue";

	// get the project id from the route
	const props = defineProps<{
		machineId: string;
		machineName: string;
	}>();
	const route = useRoute();

	// http request to get active connections
	const auditTrailsHttp = useAuditTrailsHttp();
	const machineIdRef = ref(props.machineId);
	const response = useAuditTrailsByMachine(auditTrailsHttp, machineIdRef);
	const useEndConnection = useAuditTrailsEndActiveConnection(auditTrailsHttp);
	const type = AuditTrailsPageType.History;
	const  deleteDialog =
		ref<InstanceType<typeof EndConnection>>();
	const deleteTaskResponse  = ref<DeleteTaskResponse>();
	const disclaimerDialog = ref<InstanceType<typeof Disclaimer>>();
	const confirmDialog = ref<InstanceType<typeof ConfirmDialog> | null>(null);

		async function TerminateConnection(remoteId: string) {
		remoteId = remoteId.split("/")[1];
		const result = await confirmDialog.value?.open({
			title: "Confirm Terminate Connection",
			text:
				"Are you sure you want to terminate this connection (" +
				remoteId +
				")?",
			okText: "Terminate",
			cancelText: "Cancel",
		});
		if (result) {
			try {
				console.log("Terminate connection: ", remoteId);
			
				const response = await useEndConnection.mutateAsync({
					remoteId,
				});
				console.log("response", response.data);
				deleteDialog.value?.openDialog(response.data);
					return true;
				} catch (error) {
					console.error(error);
					return false;
				}
		}
	}
	function openDisclaimerDialog() {
		disclaimerDialog.value?.openDialog();
	}
</script>
<template>

<div class="flex">
	<PageHeader
		:title="`Historical ECS Tasks: ${machineName}`"
		:back="true"
	/>

			<button
				@click="openDisclaimerDialog()"
				class="h-10 w-10 bg-bright-orange text-white rounded-full flex items-center justify-center mt-8 mx-5"
			>
				<i class="material-icons">info </i>
			</button>
		</div>
	
	<ListConnection
			:tasks="response.data.value as AuditTrailsType[]"
			:type="type"
			:isLoading="response.isLoading.value"
			@terminateConnection="TerminateConnection"
		/>
	<ConfirmDialog ref="confirmDialog" />
	<EndConnection
		ref="deleteDialog"/>
	<Disclaimer ref="disclaimerDialog" />
</template>
<style scoped></style>
