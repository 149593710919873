<script setup lang="ts">
	import { computed, ref, onMounted, onUnmounted } from "vue";

	// Define types for the options and fields
	export type Option = {
		id: string | number;
		label: string;
		[key: string]: any; // To allow additional properties from the original object
	};

	// Props
	const props = defineProps<{
		options: Option[];
		placeholder?: string;
		valueField: string;
		displayField: string;
		defaultSelected?: Option;
	}>();

	// Emits
	const emit = defineEmits<{
		(e: "update:modelValue", value: Option): void;
	}>();

	// State variables
	const isOpen = ref<boolean>(false);
	const searchQuery = ref<string>("");
	const selectedOption = ref(props.defaultSelected);

	// Dropdown ref to handle click outside
	const dropdownRef = ref<HTMLElement | null>(null);

	// Computed property to filter the options based on search query
	const filteredOptions = computed(() => {
		if (!searchQuery.value) return props.options;
		return props.options.filter(option =>
			option[props.displayField]
				.toLowerCase()
				.includes(searchQuery.value.toLowerCase()),
		);
	});

	// Methods
	const toggleDropdown = (): void => {
		isOpen.value = !isOpen.value;
	};

	const selectOption = (option: Option): void => {
		selectedOption.value = option;
		searchQuery.value = "";
		isOpen.value = false;
		emit("update:modelValue", option); // Emit selected option
	};

	// Handle click outside the dropdown
	const closeDropdown = (): void => {
		isOpen.value = false;
	};

	const handleClickOutside = (event: MouseEvent): void => {
		if (
			dropdownRef.value &&
			!dropdownRef.value.contains(event.target as Node)
		) {
			closeDropdown(); // Close the dropdown when clicked outside
		}
	};

	// Add event listener on mounted and remove on unmounted
	onMounted(() => {
		document.addEventListener("click", handleClickOutside);
	});

	onUnmounted(() => {
		document.removeEventListener("click", handleClickOutside);
	});
</script>

<template>
	<div
		ref="dropdownRef"
		class="relative h-[10rem] w-full"
	>
		<!-- Trigger for Dropdown -->
		<div
			class="bg-white body-2-bold py-2 px-4 rounded-[2rem] h-12 border border-separator outline-none items-center flex justify-between cursor-pointer"
			@click="toggleDropdown"
		>
			<span v-if="selectedOption">{{
				selectedOption?.[props.displayField]
			}}</span>
			<span
				v-else
				class="text-gray-400"
				>{{ placeholder }}</span
			>
		</div>

		<!-- Dropdown Menu -->
		<div
			v-if="isOpen"
			class="absolute left-0 top-0 z-10 w-full border border-gray-300 bg-white shadow-lg"
		>
			<!-- Search Box -->
			<input
				type="text"
				v-model="searchQuery"
				class="w-full border-b border-gray-300 px-4 py-2 focus:outline-none"
				placeholder="Search..."
			/>

			<!-- List of Options (Scrollable) -->
			<ul class="max-h-60 overflow-y-auto">
				<li
					v-for="option in filteredOptions"
					:key="option[props.valueField]"
					@click="selectOption(option)"
					class="cursor-pointer p-2 hover:bg-gray-100"
				>
					{{ option[props.displayField] }}
				</li>
				<li
					v-if="filteredOptions.length === 0"
					class="p-2 text-gray-500"
				>
					No results found
				</li>
			</ul>
		</div>
	</div>
</template>

<style scoped>
	/* Scrollable dropdown styling */
	ul {
		max-height: 7rem;
		overflow-y: auto;
		scrollbar-width: thin;
	}

	ul::-webkit-scrollbar {
		width: 0.375rem;
	}

	ul::-webkit-scrollbar-thumb {
		background-color: #c0c0c0;
		border-radius: 3px;
	}

	ul::-webkit-scrollbar-track {
		background-color: #f0f0f0;
	}

	ul li {
		cursor: pointer;
		padding: 8px 12px;
		transition: background-color 0.2s ease-in-out;
	}

	ul li:hover {
		background-color: #f5f5f5;
	}
</style>
