<script setup lang="ts">
	import AddProject from "@/components/UMC/AddProject.vue";
	import ListProject from "@/components/UMC/ListProject.vue";
	import {
		useProjectList,
		useAddProjectMutation,
		useHttp,
		Group,
		GroupType,
		useUserListByGroup,
		FlatUser,
	} from "@/composables/umc-api";
	import { AsyncDialog, ConfirmDialog } from "@jca/libs/confirm";
	import { computed, ref, watchEffect } from "vue";
	import PageHeader from "@/components/Navigation/PageHeader.vue";
	import { setCustomerIdWithProjectId } from "/src/composables/api/auth";
	import { emptyGUID } from "@jca/libs/api";
	import type { Option } from "@/components/Dropdown.vue";

	const page = ref(1);
	const maxPageSize = ref(60); //max page size for the user list

	const http = useHttp();
	const confirmDialog = ref<InstanceType<typeof ConfirmDialog> | null>(null);
	const addDialog = ref<InstanceType<typeof AsyncDialog> | null>(null);
	const { isLoading: isProjectLoading, data: projects } = useProjectList(http);

	setCustomerIdWithProjectId(emptyGUID);
	const adminGroup = ref<Group>(GroupType.PortalAdmin);
	const admins = useUserListByGroup(
		http,
		adminGroup,
		ref(emptyGUID),
		page,
		maxPageSize,
	);

	//useMutation
	const addProjectMutation = useAddProjectMutation(http);

	//Compute
	const isLoading = computed(() => {
		return isProjectLoading.value;
	});

	function addProject() {
		addProjectMutation.reset();
		addDialog.value?.open<
			void,
			{
				name: string;
				description: string;
				ownerEmail: string;
			}
		>({
			callback: async result => {
				await addProjectMutation.mutateAsync(result);
				return true;
			},
		});
	}
	function convertToOption(user: FlatUser): Option {
		return {
			id: user.Username ?? "", // Assign a default value if project.ProjectId is undefined
			label: user.Username ?? "",
			...user,
		};
	}
	const userOptions = ref<Option[]>([]);
	watchEffect(() => {
		if (admins.data.value) {
			userOptions.value = admins.data.value.map(convertToOption);
		}
	});
</script>

<template>
	<PageHeader title="Projects" />

	<div class="box-border flex min-h-0 flex-col gap-4 pl-5">
		<ListProject
			:projects="projects ?? []"
			:loading="isLoading"
			@add="addProject"
			:allow-create="true"
		/>

		<ConfirmDialog
			ref="confirmDialog"
			class="w-200"
		/>
		<AsyncDialog
			ref="addDialog"
			v-slot="slotProps"
		>
			<AddProject
				:loading="slotProps.loading"
				@close="slotProps.close($event)"
				:error="addProjectMutation.error.value?.toString()"
				:userOptions="userOptions"
			/>
		</AsyncDialog>
	</div>
</template>
