/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AdminCreateUserResponse {
	ResponseMetadata?: ResponseMetadata;
	/** @format int64 */
	ContentLength: number;
	HttpStatusCode?: HttpStatusCode;
	User?: UserType;
  }
  
  export interface AdminDeleteUserResponse {
	ResponseMetadata?: ResponseMetadata;
	/** @format int64 */
	ContentLength: number;
	HttpStatusCode?: HttpStatusCode;
  }
  
  export interface AdminDisableUserResponse {
	ResponseMetadata?: ResponseMetadata;
	/** @format int64 */
	ContentLength: number;
	HttpStatusCode?: HttpStatusCode;
  }
  
  export interface AdminEnableUserResponse {
	ResponseMetadata?: ResponseMetadata;
	/** @format int64 */
	ContentLength: number;
	HttpStatusCode?: HttpStatusCode;
  }
  
  export interface AdminGetUserResponse {
	ResponseMetadata?: ResponseMetadata;
	/** @format int64 */
	ContentLength: number;
	HttpStatusCode?: HttpStatusCode;
	Enabled: boolean;
	MFAOptions?: MFAOptionType[] | null;
	PreferredMfaSetting?: string | null;
	UserAttributes?: AttributeType[] | null;
	/** @format date-time */
	UserCreateDate: string;
	/** @format date-time */
	UserLastModifiedDate: string;
	UserMFASettingList?: string[] | null;
	Username?: string | null;
	UserStatus?: UserStatusType;
  }
  
  export interface AmazonWebServiceResponse {
	ResponseMetadata?: ResponseMetadata;
	/** @format int64 */
	ContentLength: number;
	HttpStatusCode?: HttpStatusCode;
  }
  
  export interface AttributeType {
	Name?: string | null;
	Value?: string | null;
  }
  
  export interface AuditTrailsType {
	MachineId?: string | null;
	AuditId?: string | null;
	MachineName?: string | null;
	ProjectName?: string | null;
	PublicIP?: string | null;
	StartTime?: string | null;
	EndTime?: string | null;
	RemoteId?: string | null;
  }
  
  /** @format int32 */
  export enum ChecksumValidationStatus {
	NOT_VALIDATED = 0,
	PENDING_RESPONSE_READ = 1,
	SUCCESSFUL = 2,
	INVALID = 3,
  }
  
  /** @format int32 */
  export enum CoreChecksumAlgorithm {
	NONE = 0,
	CRC32C = 1,
	CRC32 = 2,
	SHA256 = 3,
	SHA1 = 4,
  }
  
  export interface DeleteTaskResponse {
	Deleted: boolean;
	TaskArn?: string | null;
	/** @format date-time */
	StartedAt: string;
	/** @format date-time */
	StoppingAt: string;
  }
  
  export interface DeliveryMediumType {
	Value?: string | null;
  }
  
  export interface FullUserResponse {
	User?: AdminGetUserResponse;
	Groups?: string[] | null;
  }
  
  /** @format int32 */
  export enum HttpStatusCode {
	Continue = 100,
	SwitchingProtocols = 101,
	Processing = 102,
	EarlyHints = 103,
	OK = 200,
	Created = 201,
	Accepted = 202,
	NonAuthoritativeInformation = 203,
	NoContent = 204,
	ResetContent = 205,
	PartialContent = 206,
	MultiStatus = 207,
	AlreadyReported = 208,
	IMUsed = 226,
	Ambiguous = 300,
	Moved = 301,
	Redirect = 302,
	RedirectMethod = 303,
	NotModified = 304,
	UseProxy = 305,
	Unused = 306,
	RedirectKeepVerb = 307,
	PermanentRedirect = 308,
	BadRequest = 400,
	Unauthorized = 401,
	PaymentRequired = 402,
	Forbidden = 403,
	NotFound = 404,
	MethodNotAllowed = 405,
	NotAcceptable = 406,
	ProxyAuthenticationRequired = 407,
	RequestTimeout = 408,
	Conflict = 409,
	Gone = 410,
	LengthRequired = 411,
	PreconditionFailed = 412,
	RequestEntityTooLarge = 413,
	RequestUriTooLong = 414,
	UnsupportedMediaType = 415,
	RequestedRangeNotSatisfiable = 416,
	ExpectationFailed = 417,
	MisdirectedRequest = 421,
	UnprocessableContent = 422,
	Locked = 423,
	FailedDependency = 424,
	UpgradeRequired = 426,
	PreconditionRequired = 428,
	TooManyRequests = 429,
	RequestHeaderFieldsTooLarge = 431,
	UnavailableForLegalReasons = 451,
	InternalServerError = 500,
	NotImplemented = 501,
	BadGateway = 502,
	ServiceUnavailable = 503,
	GatewayTimeout = 504,
	HttpVersionNotSupported = 505,
	VariantAlsoNegotiates = 506,
	InsufficientStorage = 507,
	LoopDetected = 508,
	NotExtended = 510,
	NetworkAuthenticationRequired = 511,
  }
  
  export interface ListUsersInGroupResponse {
	ResponseMetadata?: ResponseMetadata;
	/** @format int64 */
	ContentLength: number;
	HttpStatusCode?: HttpStatusCode;
	NextToken?: string | null;
	Users?: UserType[] | null;
  }
  
  export interface ListUsersWithGroupsResponse {
	PaginationToken?: string | null;
	UsersWithGroups?: UserWithGroups[] | null;
  }
  
  export interface MFAOptionType {
	AttributeName?: string | null;
	DeliveryMedium?: DeliveryMediumType;
  }
  
  export interface NewProjectRequest {
	Project?: Project;
	ProjectOwner?: User;
  }
  
  export interface Project {
	ProjectId?: string | null;
	Name?: string | null;
	Description?: string | null;
	Deleted: boolean;
  }
  
  export interface ProjectDeleteResponse {
	Deleted: boolean;
	AttachedUsers?: UserWithGroups[] | null;
  }
  
  export interface ResponseMetadata {
	RequestId?: string | null;
	Metadata?: Record<string, string>;
	ChecksumAlgorithm?: CoreChecksumAlgorithm;
	ChecksumValidationStatus?: ChecksumValidationStatus;
  }
  
  export interface UpdateUser {
	userName?: string | null;
	projectId?: string | null;
	Groups?: string[] | null;
	Attributes?: Record<string, string | null>;
  }
  
  export interface User {
	userName?: string | null;
	groupName?: string | null;
	groupNames?: string[] | null;
	projectId?: string | null;
  }
  
  export interface UserStatusType {
	Value?: string | null;
  }
  
  export interface UserType {
	Attributes?: AttributeType[] | null;
	Enabled: boolean;
	MFAOptions?: MFAOptionType[] | null;
	/** @format date-time */
	UserCreateDate: string;
	/** @format date-time */
	UserLastModifiedDate: string;
	Username?: string | null;
	UserStatus?: UserStatusType;
  }
  
  export interface UserWithGroups {
	User?: UserType;
	Groups?: string[] | null;
  }
  
  export type QueryParamsType = Record<string | number, any>;
  export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;
  
  export interface FullRequestParams extends Omit<RequestInit, "body"> {
	/** set parameter to `true` for call `securityWorker` for this request */
	secure?: boolean;
	/** request path */
	path: string;
	/** content type of request body */
	type?: ContentType;
	/** query params */
	query?: QueryParamsType;
	/** format of response (i.e. response.json() -> format: "json") */
	format?: ResponseFormat;
	/** request body */
	body?: unknown;
	/** base url */
	baseUrl?: string;
	/** request cancellation token */
	cancelToken?: CancelToken;
  }
  
  export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;
  
  export interface ApiConfig<SecurityDataType = unknown> {
	baseUrl?: string;
	baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
	securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
	customFetch?: typeof fetch;
  }
  
  export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
	data: D;
	error: E;
  }
  
  type CancelToken = Symbol | string | number;
  
  export enum ContentType {
	Json = "application/json",
	FormData = "multipart/form-data",
	UrlEncoded = "application/x-www-form-urlencoded",
	Text = "text/plain",
  }
  
  export class HttpClient<SecurityDataType = unknown> {
	public baseUrl: string = "";
	private securityData: SecurityDataType | null = null;
	private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
	private abortControllers = new Map<CancelToken, AbortController>();
	private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);
  
	private baseApiParams: RequestParams = {
	  credentials: "same-origin",
	  headers: {},
	  redirect: "follow",
	  referrerPolicy: "no-referrer",
	};
  
	constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
	  Object.assign(this, apiConfig);
	}
  
	public setSecurityData = (data: SecurityDataType | null) => {
	  this.securityData = data;
	};
  
	protected encodeQueryParam(key: string, value: any) {
	  const encodedKey = encodeURIComponent(key);
	  return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
	}
  
	protected addQueryParam(query: QueryParamsType, key: string) {
	  return this.encodeQueryParam(key, query[key]);
	}
  
	protected addArrayQueryParam(query: QueryParamsType, key: string) {
	  const value = query[key];
	  return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
	}
  
	protected toQueryString(rawQuery?: QueryParamsType): string {
	  const query = rawQuery || {};
	  const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
	  return keys
		.map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
		.join("&");
	}
  
	protected addQueryParams(rawQuery?: QueryParamsType): string {
	  const queryString = this.toQueryString(rawQuery);
	  return queryString ? `?${queryString}` : "";
	}
  
	private contentFormatters: Record<ContentType, (input: any) => any> = {
	  [ContentType.Json]: (input: any) =>
		input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
	  [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
	  [ContentType.FormData]: (input: any) =>
		Object.keys(input || {}).reduce((formData, key) => {
		  const property = input[key];
		  formData.append(
			key,
			property instanceof Blob
			  ? property
			  : typeof property === "object" && property !== null
				? JSON.stringify(property)
				: `${property}`,
		  );
		  return formData;
		}, new FormData()),
	  [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
	};
  
	protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
	  return {
		...this.baseApiParams,
		...params1,
		...(params2 || {}),
		headers: {
		  ...(this.baseApiParams.headers || {}),
		  ...(params1.headers || {}),
		  ...((params2 && params2.headers) || {}),
		},
	  };
	}
  
	protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
	  if (this.abortControllers.has(cancelToken)) {
		const abortController = this.abortControllers.get(cancelToken);
		if (abortController) {
		  return abortController.signal;
		}
		return void 0;
	  }
  
	  const abortController = new AbortController();
	  this.abortControllers.set(cancelToken, abortController);
	  return abortController.signal;
	};
  
	public abortRequest = (cancelToken: CancelToken) => {
	  const abortController = this.abortControllers.get(cancelToken);
  
	  if (abortController) {
		abortController.abort();
		this.abortControllers.delete(cancelToken);
	  }
	};
  
	public request = async <T = any, E = any>({
	  body,
	  secure,
	  path,
	  type,
	  query,
	  format,
	  baseUrl,
	  cancelToken,
	  ...params
	}: FullRequestParams): Promise<HttpResponse<T, E>> => {
	  const secureParams =
		((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
		  this.securityWorker &&
		  (await this.securityWorker(this.securityData))) ||
		{};
	  const requestParams = this.mergeRequestParams(params, secureParams);
	  const queryString = query && this.toQueryString(query);
	  const payloadFormatter = this.contentFormatters[type || ContentType.Json];
	  const responseFormat = format || requestParams.format;
  
	  return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
		...requestParams,
		headers: {
		  ...(requestParams.headers || {}),
		  ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
		},
		signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
		body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
	  }).then(async (response) => {
		const r = response.clone() as HttpResponse<T, E>;
		r.data = null as unknown as T;
		r.error = null as unknown as E;
  
		const data = !responseFormat
		  ? r
		  : await response[responseFormat]()
			  .then((data) => {
				if (r.ok) {
				  r.data = data;
				} else {
				  r.error = data;
				}
				return r;
			  })
			  .catch((e) => {
				r.error = e;
				return r;
			  });
  
		if (cancelToken) {
		  this.abortControllers.delete(cancelToken);
		}
  
		if (!response.ok) throw data;
		return data;
	  });
	};
  }
  
  /**
   * @title WebApiSwagger
   * @version 1.0
   */
  export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
	auditTrails = {
	  /**
	   * No description
	   *
	   * @tags AuditTrailsLambda, Only lambda
	   * @name GetAuditTrailsByMachine
	   * @request GET:/audit-trails
	   */
	  getAuditTrailsByMachine: (
		query: {
		  machineId: string;
		},
		params: RequestParams = {},
	  ) =>
		this.request<AuditTrailsType[], any>({
		  path: `/audit-trails`,
		  method: "GET",
		  query: query,
		  format: "json",
		  ...params,
		}),
  
	  /**
	   * No description
	   *
	   * @tags AuditTrailsLambda, Only lambda
	   * @name GetAuditTrailsActiveAll
	   * @request GET:/audit-trails/active/all
	   */
	  getAuditTrailsActiveAll: (params: RequestParams = {}) =>
		this.request<AuditTrailsType[], any>({
		  path: `/audit-trails/active/all`,
		  method: "GET",
		  format: "json",
		  ...params,
		}),
  
	  /**
	   * No description
	   *
	   * @tags AuditTrailsLambda, Only lambda
	   * @name DeleteAuditTrailsActiveConnectionById
	   * @request DELETE:/audit-trails/active
	   */
	  deleteAuditTrailsActiveConnectionById: (
		query: {
		  remoteId: string;
		},
		params: RequestParams = {},
	  ) =>
		this.request<DeleteTaskResponse, any>({
		  path: `/audit-trails/active`,
		  method: "DELETE",
		  query: query,
		  format: "json",
		  ...params,
		}),
	};
	umc = {
	  /**
	   * No description
	   *
	   * @tags UmcLambda, Only lambda
	   * @name UsersList
	   * @request GET:/umc/users
	   */
	  usersList: (
		query: {
		  paginationToken: string;
		  /** @format int32 */
		  limit: number;
		},
		params: RequestParams = {},
	  ) =>
		this.request<ListUsersWithGroupsResponse, any>({
		  path: `/umc/users`,
		  method: "GET",
		  query: query,
		  format: "json",
		  ...params,
		}),
  
	  /**
	   * No description
	   *
	   * @tags UmcLambda, Only lambda
	   * @name GetAllUsersGroup
	   * @request GET:/umc/users/group
	   */
	  getAllUsersGroup: (
		query: {
		  groupName: string;
		  projectId: string;
		  nextToken: string;
		  /** @format int32 */
		  limit: number;
		},
		params: RequestParams = {},
	  ) =>
		this.request<ListUsersInGroupResponse, any>({
		  path: `/umc/users/group`,
		  method: "GET",
		  query: query,
		  format: "json",
		  ...params,
		}),
  
	  /**
	   * No description
	   *
	   * @tags UmcLambda, Only lambda
	   * @name UserList
	   * @request GET:/umc/user
	   */
	  userList: (
		query: {
		  userName: string;
		  projectId: string;
		},
		params: RequestParams = {},
	  ) =>
		this.request<FullUserResponse, any>({
		  path: `/umc/user`,
		  method: "GET",
		  query: query,
		  format: "json",
		  ...params,
		}),
  
	  /**
	   * No description
	   *
	   * @tags UmcLambda, Only lambda
	   * @name UserCreate
	   * @request POST:/umc/user
	   */
	  userCreate: (data: User, params: RequestParams = {}) =>
		this.request<AdminCreateUserResponse, any>({
		  path: `/umc/user`,
		  method: "POST",
		  body: data,
		  type: ContentType.Json,
		  format: "json",
		  ...params,
		}),
  
	  /**
	   * No description
	   *
	   * @tags UmcLambda, Only lambda
	   * @name UserDelete
	   * @request DELETE:/umc/user
	   */
	  userDelete: (
		query: {
		  userName: string;
		  projectId: string;
		},
		params: RequestParams = {},
	  ) =>
		this.request<AdminDeleteUserResponse, any>({
		  path: `/umc/user`,
		  method: "DELETE",
		  query: query,
		  format: "json",
		  ...params,
		}),
  
	  /**
	   * No description
	   *
	   * @tags UmcLambda, Only lambda
	   * @name UserUpdate
	   * @request PUT:/umc/user
	   */
	  userUpdate: (data: UpdateUser, params: RequestParams = {}) =>
		this.request<AmazonWebServiceResponse[], any>({
		  path: `/umc/user`,
		  method: "PUT",
		  body: data,
		  type: ContentType.Json,
		  format: "json",
		  ...params,
		}),
  
	  /**
	   * No description
	   *
	   * @tags UmcLambda, Only lambda
	   * @name UserActivateUpdate
	   * @request PUT:/umc/user/activate
	   */
	  userActivateUpdate: (
		query: {
		  userName: string;
		},
		params: RequestParams = {},
	  ) =>
		this.request<AdminCreateUserResponse, any>({
		  path: `/umc/user/activate`,
		  method: "PUT",
		  query: query,
		  format: "json",
		  ...params,
		}),
  
	  /**
	   * No description
	   *
	   * @tags UmcLambda, Only lambda
	   * @name UserReinviteUpdate
	   * @request PUT:/umc/user/reinvite
	   */
	  userReinviteUpdate: (
		query: {
		  userName: string;
		  projectId: string;
		},
		params: RequestParams = {},
	  ) =>
		this.request<AdminCreateUserResponse, any>({
		  path: `/umc/user/reinvite`,
		  method: "PUT",
		  query: query,
		  format: "json",
		  ...params,
		}),
  
	  /**
	   * No description
	   *
	   * @tags UmcLambda, Only lambda
	   * @name UserEnableUpdate
	   * @request PUT:/umc/user/enable
	   */
	  userEnableUpdate: (
		query: {
		  userName: string;
		  projectId: string;
		},
		params: RequestParams = {},
	  ) =>
		this.request<AdminEnableUserResponse, any>({
		  path: `/umc/user/enable`,
		  method: "PUT",
		  query: query,
		  format: "json",
		  ...params,
		}),
  
	  /**
	   * No description
	   *
	   * @tags UmcLambda, Only lambda
	   * @name UserDisableUpdate
	   * @request PUT:/umc/user/disable
	   */
	  userDisableUpdate: (
		query: {
		  userName: string;
		  projectId: string;
		},
		params: RequestParams = {},
	  ) =>
		this.request<AdminDisableUserResponse, any>({
		  path: `/umc/user/disable`,
		  method: "PUT",
		  query: query,
		  format: "json",
		  ...params,
		}),
  
	  /**
	   * No description
	   *
	   * @tags UmcLambda, Only lambda
	   * @name ProjectsList
	   * @request GET:/umc/projects
	   */
	  projectsList: (params: RequestParams = {}) =>
		this.request<Project[], any>({
		  path: `/umc/projects`,
		  method: "GET",
		  format: "json",
		  ...params,
		}),
  
	  /**
	   * No description
	   *
	   * @tags UmcLambda, Only lambda
	   * @name ProjectsCreate
	   * @request POST:/umc/projects
	   */
	  projectsCreate: (data: NewProjectRequest, params: RequestParams = {}) =>
		this.request<Project, any>({
		  path: `/umc/projects`,
		  method: "POST",
		  body: data,
		  type: ContentType.Json,
		  format: "json",
		  ...params,
		}),
  
	  /**
	   * No description
	   *
	   * @tags UmcLambda, Only lambda
	   * @name ProjectsDelete
	   * @request DELETE:/umc/projects
	   */
	  projectsDelete: (
		query: {
		  /** @format uuid */
		  projectId: string;
		},
		params: RequestParams = {},
	  ) =>
		this.request<ProjectDeleteResponse, any>({
		  path: `/umc/projects`,
		  method: "DELETE",
		  query: query,
		  format: "json",
		  ...params,
		}),
  
	  /**
	   * No description
	   *
	   * @tags UmcLambda, Only lambda
	   * @name GetProjectById
	   * @request GET:/umc/project
	   */
	  getProjectById: (
		query: {
		  /** @format uuid */
		  projectId: string;
		},
		params: RequestParams = {},
	  ) =>
		this.request<Project, any>({
		  path: `/umc/project`,
		  method: "GET",
		  query: query,
		  format: "json",
		  ...params,
		}),
  
	  /**
	   * No description
	   *
	   * @tags UmcLambda, Only lambda
	   * @name GetProjectByUser
	   * @request GET:/umc/projects/user
	   */
	  getProjectByUser: (
		query: {
		  userName: string;
		},
		params: RequestParams = {},
	  ) =>
		this.request<Project[], any>({
		  path: `/umc/projects/user`,
		  method: "GET",
		  query: query,
		  format: "json",
		  ...params,
		}),
	};
  }