import { useAuth } from "../auth";
import { Api } from "../api-models";

// get token
const { cachedIdToken } = useAuth();

const auditTrailsHttpClient = new Api({
	baseApiParams: { secure: true },
	securityWorker: async () => {
		return {
			headers: { Authorization: `Bearer ${cachedIdToken.value}` },
		};
	},
});
export const initAuditTrailsHttp = (defaultUrl: string) => {
	auditTrailsHttpClient.baseUrl = `${defaultUrl}`;
};

export const useAuditTrailsHttp = (): Api<unknown> =>
	auditTrailsHttpClient as Api<unknown>;