<script setup lang="ts">
import InputText from "primevue/inputtext";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
const props = defineProps<{
		filtersProp: any;
	}>();
</script>

<template>
<div class="mb-5 flex justify-between">
    <div
        class="mb-4 flex items-center rounded-full bg-netural-orange p-2.5 md:mb-0 md:mr-4"
    >
        <IconField class="flex justify-between">
            <InputIcon>
                <img
                    src="@/assets/topNav/search_icon.svg"
                    class="h-6 w-6"
                    alt="search icon"
                />
            </InputIcon>
            <InputText
                v-model="props.filtersProp.global.value"
                placeholder="Search.."
                class="ml-2 bg-transparent text-bright-orange placeholder-bright-orange focus:outline-none"
            />
        </IconField>
    </div>
</div>
</template>