<template>
	<div
		:class="{ 'w-24': !expanded, 'w-80': expanded }"
		@mouseenter="expanded = true"
		@mouseleave="expanded = false"
		class="transition-all duration-50 ease-in-out sidebar min-h-screen w-[6rem] overflow-hidden border-r hover:w-80 hover:bg-dark-green hover:shadow-lg"
	>
		<div class="flex h-screen flex-col pb-6 pt-2">
			<div>
				<!-- Show the waffle icon and list items when expanded -->
				<div
					v-if="expanded"
					class="flex flex-col justify-between pt-5"
					style="height: 90vh"
				>
					<div>
						<div
							class="w-max pl-5"
							v-if="!isDashboardIOpen"
							@mouseenter="isDashboardIOpen = true"
						>
							<img
								src="@/assets/sidebar/waffle_icon.svg"
								class="w-10"
								alt=""
							/>
						</div>

						<div
							v-if="isDashboardIOpen"
							class="mb-5 max-h-48 overflow-y-auto px-5"
							@mouseleave="isDashboardIOpen = false"
						>
							<ul class="dashboard_ul">
								<li @click="toggleSection('remote')">
									<a href="#">
										<img
											src="@/assets/sidebar/remote_access.svg"
											alt="remote access icon"
										/>
										<span> Remote Access</span>
									</a>
								</li>
							</ul>
						</div>

						<div
							class="pl-5"
							:class="{ 'mt-28': !isDashboardIOpen }"
						>
							<!-- List items -->
							<ListItem
								v-if="!isDashboardIOpen"
								@click="isDashboardIOpen = true"
								class_items="li_main"
								title="Dashboard"
								url="#"
							/>

							<div
								v-for="(section, index) in sectionList"
								:key="index"
							>
								<SectionNav
									v-if="activeSection === section.id"
									:title="section.title"
									:url="section.url"
									:itemList="section.itemList"
								/>
							</div>

							<ListItem
								class_items="li_main_bottom li_main_top mt-5"
								title="Users"
								:url= "users_url"
							/>
							<!-- Other list items -->
						</div>
					</div>

					<div class="relative flex items-end justify-center">
						<!-- User icon and username -->
						<button
							@click="toggleDropdown"
							class="relative flex flex-col items-center focus:outline-none"
						>
							<img
								src="@/assets/sidebar/user.svg"
								alt="user icon"
								class="h-10 w-10"
							/>
							<!-- Dropdown menu -->
							<div
								v-if="isDropdownOpen"
								class="absolute bottom-full left-1/2 z-10 w-48 -translate-x-1/2 transform bg-white shadow-md"
							>
								<button
									class="user_dropdown_item"
									@click="signOut"
								>
									Logout
								</button>
							</div>
							<span class="mt-1 text-white">{{ name }}</span>
						</button>
					</div>
				</div>
				<!-- Show the sidebar icon when not expanded -->
				<div v-else>
					<div class="flex items-center justify-center pt-5">
						<img
							src="@/assets/sidebar/sidebar_icon.svg"
							class="w-12"
							alt=""
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
	import SectionNav from "@/components/Navigation/SectionNav.vue";
	import ListItem from "@/components/Navigation/ListItem.vue";
	import { ref, onMounted } from "vue";
	import { useAuth } from "@/composables/auth";
	import { getCurrentUser } from "aws-amplify/auth";
	import { emptyGUID } from "@jca/libs/api";
	import { UserLevel } from "/src/composables/umc-api";
	// get user information
	const { signOut } = useAuth();

	const name = ref("");
	// side bar open or not
	const expanded = ref<boolean>(false);

	const users_url = `/${UserLevel.User}/${emptyGUID}`;

	// user dropdown
	const isDropdownOpen = ref(false);
	const toggleDropdown = () => {
		isDropdownOpen.value = !isDropdownOpen.value;
	};

	// waffle icon hover or dashboard link on click
	const isDashboardIOpen = ref(false);

	const defaultSection = "remote";
	const activeSection = ref(defaultSection); // default section
	const toggleSection = (section: string) => {
		activeSection.value =
			activeSection.value === section ? defaultSection : section;
	};

	const remoteAccessList = [
		//TODO: This part requires the Database, and it is not implemented yet (FIEL-35)
		{ url: "/remote_access_active", name: "Active ECS Tasks" },
		//{ url: "/remote_access_history", name: "Connection History" },
		{ url: "/projects", name: "Projects" },
		{ url: "/my_subscriptions", name: "My Subscriptions" }
	];

	const sectionList = [
		{
			title: "REMOTE ACCESS",
			url: "#",
			itemList: remoteAccessList,
			id: "remote",
		},
	];
	onMounted(async () => {
		const { username, userId, signInDetails } = await getCurrentUser();
		name.value = username;
	});
</script>

<style scoped>
	.user_dropdown_item {
		@apply block w-full px-4 py-2 text-sm text-bright-orange hover:bg-bright-orange hover:text-white;
	}

	.dashboard_ul {
		@apply bg-netural-orange p-4 py-2;
	}

	.dashboard_ul li {
		@apply my-2 flex items-center gap-4 bg-netural-orange p-2 font-bold text-dark-green;
	}

	.dashboard_ul li span {
		@apply text-base;
	}

	.dashboard_ul li a {
		@apply inline-flex items-center justify-between text-dark-green no-underline;
	}

	.dashboard_ul li img {
		@apply mr-2 h-8 w-8;
	}
	.dashboard_ul li:hover {
		@apply bg-pumpkin-secondary;
	}
</style>
