<script setup lang="ts">
	import ClockSpinnerIcon from "@/icons/ClockSpinnerIcon.vue";
	import { useValidation } from "@jca/libs/forms";
	import { DialogLayout, TextInput } from "@jca/libs/ui";
	import { reactive } from "vue";
	import { z } from "zod";

	const props = defineProps<{
		loading: boolean;
		error?: string | null;
	}>();

	const emit = defineEmits<{
		close: [{ machineName: string }?];
	}>();

	const machineSchema = z.object({
		machineName: z
			.string()
			.min(3, "Must be at least 3 characters long")
			.max(50, "Must be at most 50 characters long")
			.regex(
				/^(?!.*\/\/)(?!.*__)[a-zA-Z_][a-zA-Z0-9_/]{1,48}[a-zA-Z0-9_]$/,
				"It can only contain (a-z, A-Z),(0-9) and _/",
			),
	});

	const result = reactive({
		machineName: "",
	});

	const validation = useValidation(result, machineSchema);

	async function save() {
		if (validation.validate()) emit("close", result);
	}
</script>

<template>
	<DialogLayout class="w-[20rem] bg-netural-orange">
		<template #title>
			<span class="font-bold text-dark-green">Add Machine</span>
		</template>

		<TextInput
			v-model="result.machineName"
			:label="'Machine Name'"
			placeholder=" "
			:error="validation.errors.machineName"
			@blur="validation.touch.machineName"
			class="mb-2 text-dark-green"
		/>


		<template #actions>
			<button
				class="button bg-light-orange"
				:disabled="props.loading"
				@click="emit('close')"
			>
				Cancel
			</button>
			<button
				class="button bg-bright-orange"
				:disabled="props.loading"
				@click="save"
			>
				<ClockSpinnerIcon v-if="loading" />
				<span>Save</span>
			</button>
		</template>
	</DialogLayout>
</template>
