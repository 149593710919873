<script setup lang="ts">
	import DownloadIcon from "@/icons/DownloadIcon.vue";
	import { DialogLayout } from  "@jca/libs/ui";;
	import { ref } from "vue";

	const dialogRef = ref<HTMLDialogElement>();
	const error = ref(false);

	function openDialog() {
		dialogRef.value?.showModal();
	}

	function Error(message: string) {
		error.value = true;
		console.error(message);
	}

	function closeDialog() {
		dialogRef.value?.close();
	}

	defineExpose({ openDialog, closeDialog, Error });
</script>

<template>
	<dialog ref="dialogRef">
		<DialogLayout>
			<template #title>
				<span v-if="!error">DOWNLOADING</span>
				<span v-else>Error</span>
			</template>

			<div class="mt-3 flex items-center justify-center">
				<span
					class="body-2-bold"
					v-if="!error"
					>OutRun is downloading this thing.</span
				>
			</div>

			<div class="mt-3 flex items-center justify-center">
				<span
					class="body-2-bold"
					v-if="!error"
					>Here's a second sentence</span
				>
				<span
					class="body-2-bold text-error"
					v-else
					>Something went wrong</span
				>
			</div>

			<div class="mt-3 flex items-center justify-center">
				<span
					class="body-2-bold"
					v-if="!error"
				>
					<DownloadIcon />
				</span>
				<span
					class="body-2-bold text-error"
					v-else
				>
					<i class="material-icons text-6xl">error</i>
				</span>
			</div>

			<hr
				class="mt-3"
				v-if="error"
			/>

			<template
				#actions
				v-if="error"
			>
				<button
					class="button button-error"
					@click="closeDialog"
				>
					Close
				</button>
			</template>
		</DialogLayout>
	</dialog>
</template>