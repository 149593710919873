<script setup lang="ts">
	import { DialogLayout } from "@jca/libs/ui";

	defineProps<{
		title: string;
		text: string;
		cancelText?: string;
		okText?: string;
		loading?: boolean;
	}>();

	const emit = defineEmits<{
		cancel: [];
		confirm: [];
	}>();
</script>

<template>
	<DialogLayout>
		<template #title>
			{{ title }}
		</template>
		{{ text }}
		<template #actions>
			<button
				class="button"
				:disabled="loading"
				@click="emit('cancel')"
			>
				{{ cancelText }}
			</button>
			<button
				class="button"
				:disabled="loading"
				@click="emit('confirm')"
			>
				{{ okText }}
			</button>
		</template>
	</DialogLayout>
</template>

