<script setup lang="ts">
	import  AuthPage  from "/src/libs/auth/AuthPage.vue";
	import { useRouter } from "vue-router";
	import awsmobile from "../aws-exports";

	const router = useRouter();
</script>

<template>
	<AuthPage
		:api-url="awsmobile.aws_cloud_logic_custom[0].endpoint"
		:show-header="true"
		@afterSignIn="router.push({ name: 'dashboard' })"
	/>
</template>
