<script setup lang="ts">
	import ClockSpinnerIcon from "@/icons/ClockSpinnerIcon.vue";
	import type { FlatUser } from "@/composables/umc-api";
	import { DialogLayout } from "@jca/libs/ui";
	import { ref } from "vue";

	const props = defineProps<{
		user: FlatUser;
		loading: boolean;
		error?: string | null;
	}>();

	const emit = defineEmits<{
		close: [user?: FlatUser];
	}>();

	const enabled = ref(props.user.Enabled);

	function save() {
		emit("close", {
			...props.user,
			Enabled: enabled.value,
		});
	}
</script>

<template>
	<DialogLayout class="bg-netural-orange">
		<template #title>
			<span class="font-bold text-dark-green">Edit user {{ user.Email }}</span>
		</template>
		<div class="flex">
			<!-- Enable button part -->
			<button
				@click="enabled = true"
				:class="[
					'toggle',
					enabled ? 'text-bright-orange' : 'text-light-orange',
				]"
			>
				Enable
			</button>

			<!-- Disable button part -->
			<button
				@click="enabled = false"
				:class="['toggle', !enabled ? 'text-light-orange' : 'text-bright-orange']"
			>
				Disable
			</button>
		</div>
		<div
			v-if="error"
			class="text-red"
			style="bottom: -1rem"
		>
			{{ error }}
		</div>
		<template #actions>
			<button
				class="button bg-light-orange"
				:disabled="props.loading"
				@click="emit('close')"
			>
				Cancel
			</button>
			<button
				class="button bg-bright-orange"
				:disabled="props.loading"
				@click="save"
			>
				<ClockSpinnerIcon v-if="loading" />
				<span>Save</span>
			</button>
		</template>
	</DialogLayout>
</template>
<style scoped>
	.toggle {
		@apply w-1/2 px-4 py-2 font-bold transition-all duration-300 focus:outline-none;
	}
</style>
