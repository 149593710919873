import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./style.css";
import { Amplify } from "aws-amplify";
import { createI18n } from "vue-i18n";
import { VueQueryPlugin } from "@tanstack/vue-query";
import awsconfig from "./aws-exports";
import AmplifyVue from "@aws-amplify/ui-vue";
import PrimeVue from "primevue/config";
import { loadIcons } from "@jca/libs/ui";
import { setConfirmComponent } from "@jca/libs/confirm";
import ConfirmDialogContent from "./components/ConfirmDialogContent.vue";

Amplify.configure(awsconfig);

const messages = Object.fromEntries(
	Object.entries(
		import.meta.glob<{ default: any }>("./locales/*.y(a)?ml", {
			eager: true,
		}),
	).map(([key, value]) => {
		const yaml = key.endsWith(".yaml");
		const out = key.split("/")[2].slice(0, yaml ? -5 : -4);
		return [out, value.default];
	}),
);

const i18n = createI18n({
	locale: "en",
	fallbackLocale: "en",
	legacy: false,
	missingWarn: true,
	warnHtmlMessage: false,
	warnHtmlInMessage: "off",
	silentFallbackWarn: true,
	silentTranslationWarn: true,
	fallbackWarn: false,
	messages,
	datetimeFormats: {
		en: {
			short: {
				year: "numeric",
				month: "numeric",
				day: "numeric",
			},
			long: {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
			},
		},
	},
});

const app = createApp(App);

const icons = import.meta.glob("./assets/icons/**/*.svg", {
	query: "raw",
	eager: true,
}) as Record<string, string>;

async function initializeApp() {
	await loadIcons(icons, app);
}

initializeApp();
setConfirmComponent(app, ConfirmDialogContent);
app.use(router);
app.use(VueQueryPlugin);
app.use(i18n);
app.use(PrimeVue, {
	unstyled: false,
});
app.use(AmplifyVue);

app.mount("#app");
