import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { computed, type Ref } from "vue";
import type {
	Api,
    AuditTrailsType
} from "../api-models";

export enum AuditTrailsPageType {
    Active = "Active",
    History = "History",
}

export function useAuditTrailsByMachine(
	api: Api<unknown>,
	machineId: Ref<string | undefined>,
) {
	return useQuery<AuditTrailsType[]>({
		queryKey: ["machineId", machineId.value],
		queryFn: async () => {
			const response = await api.auditTrails.getAuditTrailsByMachine({
                machineId: machineId.value!,
            });
			if (response.status !== 200) throw new Error(await response.text());
			return response.data;
		},
	});
}

export function useAuditTrailsActiveConnections(
    api: Api<unknown>,
) {
    return useQuery<AuditTrailsType[]>({
        queryKey: ["activeConnections"],
        queryFn: async () => {
            const response = await api.auditTrails.getAuditTrailsActiveAll();
            if (response.status !== 200) throw new Error(await response.text());
            return response.data;
        },
    });
}


export const useAuditTrailsEndActiveConnection = (api: Api<unknown>) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async ({
			remoteId,
		}: {
			remoteId:string
		}) => {

            try {
				return  await api.auditTrails.deleteAuditTrailsActiveConnectionById({
					remoteId: remoteId,
            });
			} catch (e: any) {
				throw e.error as string;
			}
			
		},
		onSuccess: () => queryClient.invalidateQueries({ queryKey: ["activeConnections"] }),
	});
};



