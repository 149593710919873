<script setup lang="ts">
	import type { DeleteTaskResponse } from "@/composables/api-models";
	import { DialogLayout } from "@jca/libs/ui";
	import { ref } from "vue";
	import { formatDateTime } from "@jca/libs/formatting";

	const deleteTaskResponse = ref<DeleteTaskResponse>();
	const dialogRef = ref<HTMLDialogElement>();
	const downloadMessageError = ref("");
	const displayDownloadError = ref(false);

	function openDialog(_deleteTaskResponse: DeleteTaskResponse) {
		deleteTaskResponse.value = _deleteTaskResponse;
		dialogRef.value?.showModal();
	}

	function closeDialog() {
		dialogRef.value?.close();
	}
	function formatArn(arn: string) {
		return arn.split("/")[2];
	}

	defineExpose({
		openDialog,
	});
</script>

<template>
	<dialog ref="dialogRef">
		<DialogLayout>
			<template #title>
				<span>ECS Task</span>
			</template>

			<p class="body-2">
				Remote Id:
				{{
					deleteTaskResponse?.TaskArn
						? formatArn(deleteTaskResponse.TaskArn)
						: ""
				}}
			</p>
			<p class="body-2 mt-1"></p>
			<hr class="mt-3" />

			<span class="body-2-bold"
				>Status: {{ deleteTaskResponse?.Deleted ? "Stopping" : "Active" }}</span
			>

			<div class="mt-3 flex items-center justify-center">
				<ul>
					<li class="text-sm">
						Start At:
						{{
							deleteTaskResponse?.StartedAt
								? formatDateTime(new Date(deleteTaskResponse.StartedAt))
								: ""
						}}
					</li>
					<li class="text-sm">
						Stopping At:
						{{
							deleteTaskResponse?.StoppingAt
								? formatDateTime(new Date(deleteTaskResponse.StoppingAt))
								: ""
						}}
					</li>
				</ul>
			</div>

			<div class="mt-3 flex items-center justify-center">
				<i class="material-icons text-yellow-500">warning</i>
				<span class="body-2-bold text-yellow-500"
					>This action might take a min to update the end time.</span
				>
			</div>

			<hr class="mt-3" />

			<template #actions>
				<span
					v-if="displayDownloadError"
					class="body-2-bold text-error"
					>{{ downloadMessageError }}</span
				>
				<button
					class="mb-2 mr-2 flex items-center rounded bg-light-orange px-4 py-2 font-bold text-dark-orange md:mb-0"
					@click="closeDialog"
				>
					Close
				</button>
			</template>
		</DialogLayout>
	</dialog>
</template>
